@keyframes spinner
  to
    -webkit-transform: rotate(360deg)
    -moz-transform: rotate(360deg)
    transform: rotate(360deg)

::placeholder
  color: #55778A !important
  opacity: 1
  font-weight: 300

::-ms-input-placeholder
  color: #55778A !important

label
  color: #0B3954
  font-family: inherit
  font-weight: 500
  line-height: 22px
  font-size: 14px
  display: block
  margin-bottom: 8px



span.error
  display: block
  margin-top: 10px
  color: $danger
  font-size: 12px
  font-weight: 500

form.simple_form

  $thumb-size: 36px

  span.error
    display: block
    margin-top: 10px
    color: $danger
    font-size: 12px
    font-weight: 500

  span.hint
    display: block
    margin-top: 10px
    color: #55778A
    font-size: 14px

  label + span.hint
    margin: 3px 0 12px 0

  .input-wrapper
    margin-bottom: 1rem

    &:last-child
      margin-bottom: 1rem

    label
      color: #0B3954
      font-family: inherit
      font-weight: 500
      line-height: 22px
      font-size: 14px
      margin-bottom: 8px
      display: block

      abbr
        text-decoration: none

    input[type='string'], input[type='text'], input[type='email'], input[type='password'], input[type='number'], input[type='tel']
      padding: 17px 20px 14px 20px
      height: auto
      border-radius: 0px

      &:focus
        box-shadow: none

    input[type='range']
      position: relative
      background: transparent
      outline: none
      border: none
      box-shadow: none
      padding: 0
      margin: 0

    @mixin range-track
      width: 100%
      height: 8px
      color: transparent
      background: linear-gradient(90deg, #0B3B56 0%, #1B9D9F 33.4%, #F4B765 68.89%, #F39881 100%)
      border: none

    @mixin range-thumb
      height: $thumb-size
      width: $thumb-size
      border-radius: $thumb-size
      border: 1px solid #F3F8FB
      cursor: ew-resize
      background: repeating-linear-gradient(90deg, #C8D6DF 0 1.5px, transparent 0 5px) 50%/ #{12px 14px} no-repeat white

    input[type=range]::-webkit-slider-runnable-track
      @include range-track()

    input[type=range]::-moz-range-track
      @include range-track()

    input[type=range]::-ms-track
      @include range-track()

    input[type=range]::-ms-fill-lower
      display: none

    input[type=range]::-ms-fill-upper
      display: none

    input[type=range]::-webkit-slider-thumb
      -webkit-appearance: none
      margin-top: -14px
      @include range-thumb()

    input[type=range]::-moz-range-thumb
      @include range-thumb()

    input[type=range]::-ms-thumb
      @include range-thumb()


    textarea
      height: 100px
      font-size: 14px

    &.select, &.time_zone
      width: 100%
      height: auto !important
      position: relative

      &:after
        top: 53px !important

      &.no-label:after
        top: 28px !important

      label.select:after
        display: none !important
        height: auto !important

      label.select:not(.is-multiple)
        height: auto !important

      select
        width: 100% !important
        height: 3.5em !important

    &.time_zone:after
      border: 3px solid $primary
      border-radius: 2px
      border-right: 0
      border-top: 0
      content: " "
      display: block
      height: 0.625em
      margin-top: -0.4375em
      pointer-events: none
      position: absolute
      top: 50%
      right: 1.125em
      transform: rotate(-45deg)
      transform-origin: center
      width: 0.625em

    &.with-icon
      position: relative

      input.input
        padding-left: 44px
        position: relative
        z-index: 2
        background: #fff

      i
        position: absolute
        z-index: 3
        font-size: 24px
        color: $black
        left: 12px
        top: 16px

  .range-slider
    margin-top: 95px

    .bullet-container
      position: relative
      margin: 0 calc($thumb-size / 2)

      .bullet
        position: absolute
        left: 50%
        top: -93px
        width: 119px
        height: 40px
        display: flex
        justify-content: center
        padding: 0 10px
        align-items: center
        background-color: transparent
        transform: translate(-50%)

        .text
          color: white
          text-align: center

        &::after
          content: ''
          position: absolute
          top: 100%
          left: 50%
          width: 15px
          height: 15px
          transform: translate(-50%, -50%) rotate(45deg)
          background-color: inherit

    .ticks
      display: flex
      justify-content: space-between
      padding: 0 calc($thumb-size / 2)

    .tick
      position: relative
      display: flex
      justify-content: center
      width: 1px

      .line
        color: #C8D6DF

      .tick-number
        color: #55778A
        position: absolute
        left: 0
        bottom: 30px
        transform: translateX(-50%)



  .scroll-area

    span.checkbox
      width: 50%
  span.checkbox
    margin-bottom: 0.5rem

    .is-checkradio[type="checkbox"] + label::before, .is-checkradio[type="checkbox"] + label:before
      background: #fff

    .is-checkradio[type="checkbox"]:checked + label::before, .is-checkradio[type="checkbox"]:checked + label:before
      background: #0D9DA4
      border-color: transparent
      border: none

    .is-checkradio[type="checkbox"]:checked + label::after, .is-checkradio[type="checkbox"]:checked + label:after
      border-color: white

    input
      padding: 0

    label
      padding-top: 0px
      font-size: 14px

    &:last-child
      margin: 0

  input.boolean.is-checkradio
    width: 100%
    height: 100%
    z-index: 999

  .check_boxes_inline_thirds span.checkbox
    width: 33%
    margin-bottom: 0
    display: inline-block

  .check_boxes_inline_halves
    display: flex
    flex-wrap: wrap

    &.single_column
      column-count: 1

  .input-wrapper.radio_buttons
    margin-bottom: 0

  .is-checkradio[type="radio"]:checked + label::before,
  .is-checkradio[type="radio"]:checked + label::before,
  .is-checkradio[type="checkbox"]:checked + label::before,
  .is-checkradio[type="checkbox"]:checked + label::before
    border: 1px solid #0B3954

  .is-checkradio[type="radio"] + label::after,
  .is-checkradio[type="radio"] + label::after
    background: #0D9DA4

  span.radio
    margin-bottom: 0rem

    input
      padding: 0

    label
      padding-top: 3px
      font-size: 14px

    &:last-child
      margin: 0

  button[type='submit']
    border: 1px solid #C8D6DF
    background: transparent
    width: 100%
    margin: 0
    outline: 0
    box-shadow: none
    color: #0B3954
    position: relative
    height: auto
    border-radius: 50px


    &:hover
      background: #0B3954
      border-color: #0B3954
      color: white



    &.working
      // background: $success
      color: transparent
      // border-color: $success
      cursor: not-allowed

      &:after
        @include icon
        content: "\e912"
        box-sizing: border-box
        position: absolute
        animation: spinner 0.8s linear infinite
        left: calc(50% - (1em / 2))
        top: calc(50% - (1em / 2))
        height: 1em
        width: 1em
        color: white

  .sms-code-input
    text-align: center
    margin-bottom: 1rem

    .input-wrapper
      display: inline-block
      margin: 0
      padding: 0 0.5rem
      width: 80px

      input
        font-size: 30px
        width: initial
        text-align: center

      @include mobile
        width: auto
        padding: 0 0.2rem

        input
          font-size: 20px
