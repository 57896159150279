body.practitioners

  div[style*="z-index: 2000000000"]
    @include mobile
      margin: 0px auto !important
      left: 0px !important
      right: 0px !important

  .navbar-list-practice
    @include mobile
      margin-top: 8px

  .grecaptcha-badge
    opacity: 0
    
  .swiper
    display: flex
    flex-flow: column-reverse
    height: 100%
    width: 100%

  .swiper-slide
    background: #fff
    display: -webkit-box
    display: -ms-flexbox
    display: -webkit-flex
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-justify-content: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    -webkit-align-items: center
    align-items: center

  .swiper-pagination
    display: flex
    width: 100%
    justify-content: center
    gap: 8px
    @include mobile
      display: none

  .swiper-pagination-bullet
    height: 150px
    width: 150px
    img
      border-radius: 4px

  .swiper-pagination-bullet-active
    border: 1px solid #0B3954

  .swiper-button-next
    @include mobile
      display: none
    width: 50px
    height: 50px
    position: absolute
    right: 10%
    top: 30%
    z-index: 99
    border: solid #0B3954
    border-width: 0 3px 3px 0
    display: inline-block
    padding: 3px
    transform: rotate(-45deg)
    -webkit-transform: rotate(-45deg)
    &:hover
      cursor: pointer

  .swiper-button-prev
    @include mobile
      display: none
    width: 50px
    height: 50px
    position: absolute
    left: 10%
    top: 30%
    z-index: 99
    border: solid #0B3954
    border-width: 0 3px 3px 0
    display: inline-block
    padding: 3px
    transform: rotate(135deg)
    -webkit-transform: rotate(135deg)
    &:hover
      cursor: pointer

  .modal[data-name='contact-practitioner']
    z-index: 99999
    .modal-content
      top: 100px
    .btn-primary
      background: #0D9DA4 !important
      border-radius: 4px !important
    @include mobile
      padding-right: 20px
      left: -20px
      .modal-content
        top: 0
footer
  border-top: 1px solid #E4EFF6
