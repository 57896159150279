.algolia-autocomplete
  width: 100%

.algolia-autocomplete .aa-input, .algolia-autocomplete .aa-hint
  width: 100%

.algolia-autocomplete .aa-hint
  color: #999

.algolia-autocomplete .aa-dropdown-menu
  width: 100%
  background-color: #fff
  border: 1px solid $grey-light
  padding: 15px 0px
  border-radius: 16px

.autosuggest.unlimited-results .aa-dropdown-menu
  max-height: 350px
  overflow-y: scroll

.algolia-autocomplete .aa-dropdown-menu .header
  padding: 5px 20px
  @include subheading
  color: $contrast

  &.buffer
    margin-top: 1rem
    display: inline-block

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion
  cursor: pointer
  padding: 8px 20px 5px 20px

  .detailed-result
    display: block
    position: relative
    padding-left: 65px
    min-height: 45px

    img
      border-radius: 50%
      width: 45px
      height: 45px
      position: absolute
      top: 0px
      left: 0px

    .name
      display: block
      @include headingfont

    .description
      color: $grey
      font-size: 0.8em
      line-height: 10px !important

.algolia-autocomplete .aa-dropdown-menu .no-results
  color: $grey
  display: block
  padding: 0px 20px

.no-results-btn 
  width: 100%
  height: 48px
  border: 0
  background-color: white
  text-align: left
  font-size: 16px
  padding-left: 12px

  &:hover
    background-color: $primary
    color: white
    cursor: pointer


.algolia-autocomplete .no-results-line
  color: $grey
  display: block
  padding: 0px 20px
  border-top: 1px solid #000
  

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em
  font-weight: bold
  font-style: normal
  color: $contrast

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor
  background-color: $contrast
  color: white

  em
    color: white

.autocomplete-selected-items
  label
    display: block
    margin-bottom: 10px

  &.no-items
    display: none

  &.with-items
    margin: 1.2rem 0 1rem 0

  span
    background: white
    color: #0B3954
    display: inline-block
    margin-bottom: 0.5rem
    padding: 10px 35px 8px 20px
    position: relative
    margin-right: 5px
    font-size: 14px
    border-bottom: 1px solid #C8D6DF

    i
      position: absolute
      right: 12px
      top: 13px
      cursor: pointer

  &.endorsements-modal
    span
      background: white
      color: #0B3954
      display: block
      margin-bottom: 0.5rem
      padding: 10px 35px 8px 24px
      position: relative
      margin-right: 5px
      font-size: 14px
      border-bottom: 1px solid #C8D6DF

      i
        position: absolute
        right: 12px
        top: 13px
        cursor: pointer
      
      .move-icon
        position: absolute
        left: 0
        top: 13px
      
      .name
        display: inline-block
        font-weight: 500
        font-size: 16px
        line-height: 24px
        letter-spacing: -0.01em
        color: #0B3954
      
      .divider, .counter
        display: inline-block
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 24px
        color: #55778A

  &.pro-search
    max-width: 80%
    margin: 0 !important
    label
      margin-bottom: 0
    &.with-items
      margin: 0
    span
      margin-bottom: 0
      font-size: 12px
      padding: 4px 30px 4px 8px
      margin-top: 2px
      i
        right: 6px
        top: 8px

    @include mobile
      span
        padding: 4px
        margin-top: 10px

        i
          position: relative
          left: 0
          top: 4px
          border: 0
          font-size: 18px
