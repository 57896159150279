span.badge
  position: relative
  display: inline-block
  padding: 8px 12px 5px 12px
  border-radius: 30px
  @include subheading
  color: white
  font-size: 11px
  background: $black
  font-weight: 600
  letter-spacing: 0.1em

  &[data-state=approved], &[data-state=settled]
    background: $success

  &[data-state=pending]
    background: #ffbe57

  &[data-state=rejected], &[data-state=declined], &[data-state=cancelled], &[data-state=expired], &[data-state=failed], &[data-state=unavailable]
    background: #EB5757