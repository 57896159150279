.locality-item, .specialty-item
  a
    color: $black
    position: relative
    display: block
    height: 48px
    line-height: 48px
    padding-left: 60px

    i
      position: absolute
      top: 0px
      left: 0px
      width: 48px
      height: 48px
      line-height: 48px
      font-size: 25px
      background: white
      border-radius: 12px
      border: 1px solid $grey-light
      @include shadow
      text-align: center
      @include transition(all)

    span
      font-size: 14px

      &:after
        @include icon
        content: "\e944"
        color: $grey
        padding-left: 8px
        font-size: 18px
        display: inline-block
        vertical-align: sub

    &:hover
      i
        background: $contrast
        color: white

      span
        color: $contrast

        &:after
          color: $contrast

.specialty-item a i
  border-radius: 50%

body.localities.show
  section.hero
    margin-bottom: 3rem

    @include mobile
      margin-bottom: 1.5rem

    .inner
      padding: 4rem 0 7rem 0
      background: #E4EFF6
      background-repeat: no-repeat
      background-position: center bottom
      background-size: cover
      text-align: left

      @include mobile
        padding: 2rem 0 3rem 0
        background-size: contain

      .center-vertically
        top: 16%
        position: relative

    h3
      font-family: Manrope
      font-style: normal
      font-weight: bold
      font-size: 40px
      line-height: 48px
      letter-spacing: -0.01em
      color: #0B3954
      margin-bottom: 24px

    p.instruction
      font-family: DM Sans
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 24px
      color: #0B3954
      margin-bottom: 36px


    .cards
      margin-top: 0
      
      .card-content
        display: flex

        .left, .right
          display: inline-block
          position: relative

        .media-left
          top: 30%
          position: relative
          margin-right: 24px
          i
            font-size: 24px
            font-weight: bold
            color: #0B3954
        
        .media
          margin-bottom: 4px

          .media-content
            font-weight: 500
            font-size: 16px
            line-height: 24px
            letter-spacing: -0.01em
            color: #0B3954

          .content
            font-size: 14px
            line-height: 20px
            letter-spacing: -0.01em
            color: #55778A


      @include mobile
        margin-top: 0
        padding: 0 0.75rem

  nav.breadcrumb
    margin-bottom: 0

    @include mobile
      ul
        justify-content: center
