.tabs, .tabs:not(:last-child)
  margin-bottom: 0.5rem

.tabs ul
  border-bottom-color: transparent

  li
    margin-right: 1em

    a
      padding: 0.5em 0em
      @include h5style
      color: $grey
      border-bottom-color: transparent

      &.with-count
        position: relative
        padding-right: 1.8em

        span.count
          position: absolute
          font-size: 11px
          font-weight: 600
          width: 25px
          height: 25px
          line-height: 28px
          text-align: center
          border-radius: 50%
          top: 8px
          right: 0px
          background: $tabs-link-color
          color: white
          @include transition(background)

        &:hover span.count
          background: $tabs-link-hover-color

    &.is-active a.with-count span.count
      background: $tabs-link-active-color

.tab-panels
  .tab-panel
    display: none

    &.is-active
      display: block