.review

  @include mobile
    width: auto

  .helpful_section
    display: flex

    .helpful_count
      font-family: DM Sans
      font-style: normal
      font-weight: normal
      font-size: 12px
      color: #55778A
      padding: 8px


    .helpful_button_click
      top: 16px
      width: 100px
      height: 32px
      z-index: 20
      border: 1px solid #C8D6DF
      border-radius: 50px
      border-color: #EDB364

      a
        display: inline-block
        padding: 6px 16px 8px 16px
        @include transition(all)
        font-family: DM Sans
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 16px
        letter-spacing: -0.01em
        background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        background-size: 100%
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        
        i, span
          vertical-align: middle


  

  .author
    margin-bottom: 20px

    &.bottom
      margin-bottom: 0px

    .with-photo
      position: relative

      .photo
        position: absolute
        top: 0px
        left: 0px

        img
          max-width: 48px
          max-height: 48px
          border-radius: 40%
    
    .with-small-photo
      position: relative
      display: inline-block
      padding: 10px


      .photo

        img
          max-width: 24px
          max-height: 24px
          border-radius: 40%
          
    span.rating
      padding-top: 12px
      background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      background-size: 100%
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    .small-metadata
      position: relative
      font-family: DM Sans
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: -0.01em
      color: #55778A
      display: inline-block
      padding: 10px
      height: 49px
      top: -6px

      
    .metadata
      font-family: DM Sans
      font-style: normal
      font-weight: 500
      font-size: 14px
      color: #55778A
      margin-top: 8px

      span
        display: inline-block

      span.rating
        background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        background-size: 100%
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent

      span.divider
        padding: 0 6px

      @include mobile
        font-size: 12px

  

  .body
    font-family: DM Sans
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    color: #0B3954

    a
      text-decoration-line: underline
      color: #0D9DA4

.card.review-request
  position: relative
  margin-bottom: 2rem

  .practitioner-photo
    position: absolute
    top: 15px
    left: 15px

    img
      width: 64px
      height: 64px
      border-radius: 50%

  .inner
    .top-level-items
      padding: 15px 15px 5px 95px

      .practitioner-name
        @include h4style

      .practitioner-title
        @include h5style
        margin-top: 4px
        font-weight: 300

    .form
      padding: 15px

      .rating-selector
        display: inline-block
        font-size: 30px
        margin-bottom: 5px
        background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        background-size: 100%
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        letter-spacing: -0.1em

      form.simple_form
        .rating-selector
          &:hover
            background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
            background-size: 100%
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            letter-spacing: -0.1em

          i
            cursor: pointer

      .input-wrapper
        margin-bottom: 0.5rem

  .action-bar
    text-align: center
    padding: 15px 5px 12px 0px
    border-top: 1px solid $grey-light

    @include mobile
      padding-left: 15px
      padding-right: 15px

      a
        width: 50%
        margin: 0

