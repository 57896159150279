body.testimonials
  .container
    @include mobile
      margin-left: 0
      margin-right: 0
      width: auto

      .column
        padding: 0.75rem 16px

    @include tablet-only
      margin-left: 50px
      margin-right: 50px

    @include from($widescreen)
      max-width: 1100px
  
  .action-call
    .link
      text-decoration-line: underline
      display: inline-block
    
    &:hover
      a
        color: #0D9DA4
      span.i
        &::before
          color: #0D9DA4

  .hero-section
    position: relative
    height: 720px

    @include mobile
      margin-bottom: 8rem

    @include from($widescreen)
      max-height: 900px
      min-height: 800px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 40%
      height: 90%
      top: 0px
      right: 0px

      @include mobile
        width: 100%
        height: 40%
        top: auto
        bottom: -120px

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 2
      right: 56px
      margin-top: 6%


      @include mobile
        position: relative
        margin: 3rem 15px 0 15px
        top: auto
        width: auto
        right: auto

      img
        max-height: 600px
        max-width: 500px

        @include from($widescreen)
          max-height: 732px
          max-width: 880px

    .container
      position: relative
      z-index: 3
      display: inline-block
      float: left
      width: 50%

      @include mobile
        top: 0
        width: auto
        display: block
        clear: both

      @include from($widescreen)
        


    .initial-adjective
      position: absolute
      word-wrap: normal 
      margin-left: 8px
      background: -webkit-linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      @include mobile
        margin-left: 4px

    h1
      margin-top: 4rem
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 48px
      line-height: 60px
      color: #0B3954
      

      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.01em
        padding-right: 10%
      
      @include from($widescreen)
        margin-top: 25%

    h2
      @include homepage-standard
      margin-top: 20px
      font-size: 16px
      line-height: 26px
      padding-right: 5%
      color: #0B3954
      letter-spacing: 0.02em

      @include mobile
        font-size: 14px
        margin-top: 1rem
        line-height: 22px

    .action-call
      text-align: left
      margin-top: 3rem

      @include mobile
        margin-top: 2rem

      @include from($tablet)
        a.button.btn-primary
          padding: 33px 52px 31px 52px !important
          font-size: 17px !important
          line-height: 16px !important
          height: 64px !important

  .testimonials-section
    .initial-adjective
      word-wrap: normal 
      margin-left: 8px
      background: -webkit-linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      display: inline

      @include mobile
        margin-left: 4px

    h1
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 40px
      line-height: 48px
      color: #0B3954
      display: block
      margin-bottom: 80px
      margin-top: 100px

      @include mobile
        font-size: 24px
        text-shadow: none
        line-height: 32px
        letter-spacing: -0.01em
        padding-right: 10%
        margin-top: 56px
        margin-bottom: 28px
      
      @include from($widescreen)
        margin-top: 140px
        
    .testimonial-container
      border-bottom: 1px solid #C8D6DF
      margin-bottom: 32px

      &:last-child
        border-bottom: 0
        margin-bottom: 140px

        @include mobile
          margin-bottom: 56px

      .column.is-narrow
        padding-left: 0
      
      @include mobile
        margin-left: 16px
        margin-right: 16px

      .author-photo
        display: inline-block
        position: relative
        height: 160px
        width: 160px
        margin-bottom: 20px

        @include mobile
          height: 72px
          width: 72px
          margin-bottom: 4px

      .author-info
        display: inline-block
        position: relative
        padding-left: 32px
        margin-bottom: 32px

        @include mobile
          padding-left: 0
          margin-bottom: 0

        .name
          font-weight: bold
          font-size: 24px
          line-height: 32px
          letter-spacing: -0.01em
          color: #0B3954
          margin-bottom: 4px

          @include mobile
            font-size: 16px
            line-height: 22px
            margin-bottom: 2px

        .profession
          font-weight: 500
          font-size: 16px
          line-height: 24px
          letter-spacing: -0.01em
          color: #0D9DA4
          margin-bottom: 12px

          @include mobile
            font-size: 14px
            line-height: 22px
            margin-bottom: 4px

        .review-body
          font-weight: normal
          font-size: 16px
          line-height: 24px
          letter-spacing: 0.01em
          color: #0B3954
          display: block
          position: relative

          @include mobile
            font-size: 14px
            line-height: 22px
            width: 100%

        .rating
          position: absolute
          right: 0
          top: 10px

          span.stars
            background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
            background-size: 100%
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            letter-spacing: -0.1em

          span.avg
            font-size: inherit
            font-weight: normal
            color: #55778A
            position: relative
            top: -1px
            margin-left: 8px

          @include mobile
            position: relative
            top: auto

    .container.reviews
      margin: 0 280px

      @include mobile
        margin: auto

  .join-now-section
    position: relative
    height: 700px

    @include mobile
      margin-bottom: 0rem
      height: 550px
      background: #E4EFF6

    @include from($widescreen)
      max-height: 900px
      min-height: 700px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 30%
      height: 100%
      top: 0px
      left: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 2
      top: 38px
      width: 45%
      float: left


      @include mobile
        position: relative
        margin: 20px 15px 0 15px
        top: auto
        width: auto
        right: auto
        left: 0
        clear: both
      
      @include from($widescreen)
        top: 20%

      img
        max-height: 534px
        max-width: 512px

        @include mobile
          max-height: 534px
          max-width: auto !important

        @include from($widescreen)
          max-height: 534px
          max-width: 512px
      

    .container
      position: relative
      z-index: 3
      top: 100px
      

      @include mobile
        clear: both
        width: auto
        margin-left: 0
        margin-right: 0
        float: none
        top: 56px
        display: block
        clear: both

      @include from($widescreen)
        top: 16%


    .initial-adjective
      position: relative
      margin-right: 5%
      margin-left: 30%
      top: -8px
      word-wrap: normal 
      background: -webkit-linear-gradient(225.47deg, #0BB2BA 1%, #0B3954 113.27%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      font-style: normal
      font-weight: bold
      font-size: 24px
      line-height: 32px
      text-align: right
      letter-spacing: -0.01em

      @include mobile
        font-size: 16px
        line-height: 24px
        margin-right: 0
        margin-left: 30%

    h1
      margin-top: 46px
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.03em
      font-size: 48px
      line-height: 56px
      color: #0B3954
      

      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-top: 56px

    h2
      @include homepage-standard
      font-style: normal
      font-weight: bold
      font-size: 40px
      line-height: 48px
      letter-spacing: -0.03em
      color: #0B3954
      margin: 0
      display: inline-block

      @include mobile
        font-size: 30px
        margin-top: 4px
        line-height: 38px

    .action-call
      text-align: left
      margin-top: 24px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #0B3954
      margin-bottom: 32px
      position: relative
      top: 30%

      @include mobile
        margin-top: 2rem
        position: absolute
        bottom: 20px
        left: 0
        top: auto
        width: 100%
        a.button.btn-primary
          width: 92%
          margin: 16px
          margin-bottom: 8px
          
    .btn-primary
      margin-top: 8px !important
      width: 100% !important
      height: 64px !important
      font-size: 17px !important


      @include mobile
        width: 100% !important
        position: relative
    
    .schedule-btn
      font-style: normal
      font-weight: bold
      font-size: 17px
      line-height: 16px
      text-align: center
      letter-spacing: 0.03em
      text-transform: uppercase
      color: #55778A
      position: relative
      top: 0
      margin-left: 27px

      @include mobile
        top: 0
        display: block
        margin-left: 0
        margin: auto
      
      &:hover
        color: #0B3954

    .professional-logo
      margin-top: 20%

      @include mobile
        margin-top: 0
      
      img
        max-height: 40px
    
    .line
      margin: 8px 0
      i
        display: inline-block
        font-size: 24px
        position: relative
        top: 6px
      p
        display: inline-block
        font-style: normal
        font-weight: 500
        font-size: 16px
        line-height: 24px
        letter-spacing: -0.01em
        color: #0B3954
        margin-left: 12px
    
    .buttons
      margin-top: 8px

  .modal
    z-index: 999
    .modal-content
      max-width: 1110px
      width: auto
      height: 100vh

      @include mobile
        max-width: 100%
        max-height: 90%

  footer
    z-index: 3
    margin-top: 0 !important

    @include mobile
      clear: both