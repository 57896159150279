body.contacts
  footer
    margin-top: 0

  h3
    margin-bottom: 1rem

    @include mobile
      margin-top: .5rem

  @include mobile
    section.section
      padding: 1rem
  
  .columns
    margin-bottom: 0px

  .column.body 
    padding-top: 75px
    padding-bottom: 75px
    

  .form-container
    padding: 26px 0px 32px 0px
    

    @include mobile
      padding: 15px 15px 20px 15px

    h3
      font-size: 24px
      margin-bottom: 1rem

      @include mobile
        margin-top: 0
    
    .completed
      margin-bottom: 12rem

  form.simple_form
    input[type=text], input[type=email], input[type=tel], select
      border-radius: 0px
      color: $homepage-primary-color
      padding: 15px 15px 12px 15px
      font-size: 14px
      height: auto
      border: 1px solid rgba($homepage-primary-color, 0.2)
      background: #fff

      &::placeholder
        color: $homepage-primary-color !important
        opacity: 0.5

      &::-ms-input-placeholder
        color: $homepage-primary-color !important
        opacity: 0.5
    
    select
        color: $homepage-primary-color !important
        opacity: 0.5
        
        &.touched
          opacity: 1




    .input-wrapper
      margin-bottom: 1.2rem

    .input-wrapper.select:after
      top: 23px !important
      right: 15px !important
      content: "\e995"
      border: none
      font-family: "icomoon"
      transform: rotate(90deg)
      font-size: 21px

    button
      font-weight: 500
      border-radius: 100px
      font-size: 17px
      letter-spacing: 0.03em
      text-transform: uppercase
      padding: 22px 36px 20px 36px
      text-align: center
      box-shadow: none
      background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      color: white
      margin-top: 15px
      cursor: pointer
      border: 0
      @include transition(all)

      @include mobile
        padding: 16px 36px 14px 36px
        font-size: 16px
        font-weight: 500

      &:hover
        background: #EDB364
        color: white

      &:after
        display: none
  .column.b 
    position: relative

  .underlayer
    position: absolute
    background: #E4EFF6
    z-index: 1
    width: 30%
    height: 90%
    top: 0px
    right: 0px
    max-height: 750px

    @include mobile
      display: none
    
  .image.hero-img
    top: 175px
    right: 0px
    position: absolute
    z-index: 99
    width: 50%

    @include mobile
      display: none
    
    img
      width: auto
      margin-left: 100px
      max-height: 530px

    
    
