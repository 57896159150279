$black:        #0B3954 !default
$black-bis:    hsl(0, 0%, 7%) !default
$black-ter:    hsl(0, 0%, 14%) !default
$black-light:  #5a5e66

$grey-darker:  hsl(0, 0%, 21%) !default
$grey-dark:    hsl(0, 0%, 29%) !default
$grey:         hsl(0, 0%, 48%) !default
$grey-light:   #dcdfe6 !default
$grey-lighter: hsl(0, 0%, 86%) !default
$grey-lightest: hsl(0, 0%, 93%) !default
$grey-background: #f6f8fa

$hr-background-color: #dcdfe6 !default
$hr-height: 1px
$hr-margin: 2.25rem 0

$white-ter:    hsl(0, 0%, 96%) !default
$white-bis:    hsl(0, 0%, 98%) !default
$white:        hsl(0, 0%, 100%) !default

$orange:       hsl(14,  100%, 53%) !default
$yellow:       hsl(48,  100%, 67%) !default
$green:        hsl(141, 53%,  53%) !default
$turquoise:    hsl(171, 100%, 41%) !default
$cyan:         hsl(204, 71%,  53%) !default
$blue:         hsl(217, 71%,  53%) !default
$purple:       hsl(271, 100%, 71%) !default
$red:          hsl(348, 86%, 61%) !default

$primary:      #0B3954
$contrast:     #2d52a3
$text:         #333438
$link:         $primary


$dark-blue: #0B3954

$navbar-item-hover-background-color: white

$tabs-link-active-border-bottom-color: $primary
$tabs-link-active-color: $primary
$tabs-link-hover-color: $contrast
$tabs-link-hover-border-bottom-color: $contrast
$tabs-link-color: $grey
$tabs-border-bottom-width: 2px

$body-family: 'Manrope', sans-serif !default
$family-sans-serif: 'Manrope', sans-serif !default
$family-monospace: monospace !default
$render-mode: optimizeLegibility !default

$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 1rem !default
$size-7: 0.75rem !default

$weight-normal: 300 !default

$radius-small: 0px !default
$radius: 0px !default
$radius-large: 6px !default
$radius-rounded: 290486px !default

$input-radius: 0px

$section-padding: 1.5rem

$card-radius: 0rem


@import 'bulma/sass/utilities/all'
@import 'bulma/sass/base/all'
@import 'bulma/sass/elements/all'
@import 'bulma/sass/form/all'
@import 'bulma/sass/components/all'
@import 'bulma/sass/grid/all'
@import 'bulma/sass/helpers/all'
@import 'bulma/sass/layout/all'

@include mobile
  .columns
    margin-left: 0
    margin-right: 0

.container.is-fluid
  padding-left: 25px
  padding-right: 25px

@include from($desktop)
  .columns.is-variable.is-10
    --columnGap: 4rem

@include until($desktop)
  .columns .column
    padding: 0.75rem 0

  .columns.is-variable
    margin-left: -0.75rem
    margin-right: -0.75rem

.select:not(.is-multiple):not(.is-loading)::after 
  border-color: #0B3954
  right: 1.125em
  z-index: 4
  border-width: 2px
  margin-top: 0px

b 
  font-weight: 500
  color: #0B3954

.dropdown-content
  padding: 15px 10px

.spinner 
  width: 50px
  height: 50px
  display: none
  position: fixed
  top: 30vh
  left: calc(50vw - 50px)


.user-dropdown
  .navbar-dropdown
    padding-top: 0 !important
    padding-bottom: 0 !important
    border-top: none
  .dropdown-content
    padding: 15px 0
    width: auto
    border-radius: 0px

  .dropdown-item
    margin-left: 0
    font-size: 16px
    padding: 0.375rem 1rem 0.375rem 45px
    position: relative

    i
      position: absolute
      top: 6px
      left: 15px
      font-size: 20px
      color: $primary
