.messaging-widget
  position: fixed
  z-index: 9999
  bottom: 2rem
  left: 2rem
  width: 350px
  background: white
  @include shadow
  border-color: $primary
  padding: 12px 20px 5px 20px
  border-radius: 16px

  @include mobile
    max-width: 90%
    bottom: 1rem
    left: 5%

  .profile-image
    background: white
    border: 1px solid $primary
    position: absolute
    left: 20px
    top: -22px
    border-radius: 50%
    width: 59px
    height: 59px

    img
      border-radius: 50%
      width: 53px
      height: 53px
      margin-left: 2px
      margin-top: 2px
      display: inline-block

  .profile-name
    font-size: 16px
    padding-left: 70px
    font-weight: 400

  .help-text
    font-size: 14px
    color: $black-light
    margin: 1rem 0

  .form
    display: none

  form.simple_form
    background: white
    border-radius: 0

    .input-wrapper
      margin-bottom: 5px

    input
      font-size: 14px

    input[type='email'], input[type='text']
      padding: 10px 12px 8px 12px
      border-radius: 12px

    textarea
      border-radius: 12px

    button
      padding: 10px 12px 8px 12px
      font-size: 14px

      &:after
        top: 8px

  .btn-close
    display: inline-block
    margin-top: 10px
    text-align: center
    font-size: 12px
    width: 100%
    color: $grey

    &:hover
      color: $primary

  .message-successful
    color: $success
    font-weight: 500
    text-align: center
    margin-bottom: 1rem

  