body.articles.show
  .itembox.inline
    display: inline-block !important
    
  h1 
    font-size: 40px
    
  .article-cover
    height: 500px
    width: 100%

    @include mobile
      height: 300px

    img
      object-fit: cover
      width: 100%
      height: 100%

  .article-body
    word-break: break-word
    font-size: large

    p
      margin-bottom: 1rem

    p + h1, p + h2, p + h3
      margin-top: 2rem

    ol
      padding-left: 30px

    a
      font-size: inherit !important

article.media.author
  figure.media-left

    .image
      padding: 2px
      margin-left: 4px
      display: inline-block

      img
        width: 55px
        height: 55px

  .media-content
    .content
      .author-name
        a
          color: $black
          font-weight: 500

          &:hover
            color: $primary

      .description
        margin-top: 3px
        font-size: 10px
        color: $black-light
        text-transform: uppercase
        display: inline-block

      .date
        margin-top: 3px
        font-size: 12px
        color: $black-light
        display: inline-block

      .social-media
        align-content: right
        .block
          display: inline-block

      ul
        list-style-position: outside
        

.article-card
  @include shadow
  position: relative
  overflow: hidden
  @include transition(all)
  width: 208px
  margin: 8px 

  a
    position: absolute
    z-index: 10
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    display: block

  .cover
    height: 170px
    position: relative
    z-index: 1

    img
      object-fit: cover
      width: 100%
      height: 100%

  .inner
    padding: 16px 16px 8px 16px
    background: white
    position: relative
    z-index: 1

    .author-image
      position: absolute
      width: 40px
      height: 40px
      left: 16px
      top: -20px
      border-radius: 50%
      padding: 2px
      border: 1px solid $primary
      background: white

      img
        width: 40px
        width: 40px
        border-radius: 50%

    .title
      @include h4style
      margin-bottom: 0.6rem
      @include transition(color)

    .author-name
      font-size: 14px
      color: $black-light
      @include transition(color)

  &:hover
    transform: scale(1.02)

    .inner
      background: $contrast
      color: white

      .title, .author-name
        color: white

.locality-section
  position: relative

  @include mobile
      padding: 3rem 0 4rem 0

  #tab-content .columns.sections
    display: flex

    @include mobile
      display: block

  ul.accordion 
    list-style: none
    padding: 0

    .inner 
      overflow: hidden
      display: none
      position: relative
      z-index: 2
      padding: 0.5em 1em

      li
        &::marker
          content: "\2022"
          color: #E9856D
          position: absolute
          left: 0
          font-size: 1.2em
          line-height: 24px
          height: 24px


        a
          color: #0B3954
          margin-left: 1em
          display: block
          padding-top: 6px
          padding-bottom: 6px

          &:hover
            color: #E9856D

        &.last

          a
            color: #E9856D
            margin-left: 0
            text-transform: uppercase
            font-size: 12px
            font-weight: 600

          &::marker
            content: " "
      
        &.show 
          border-radius: 0.15em
          margin-top: 3px

    li 
      margin: .5em 0
        
      a.toggle 
        width: 100%
        display: block
        background: transparent
        color: #0B3954
        padding: .75em
        border-radius: 0em
        transition: background .3s ease
        position: relative
        z-index: 3
        border-bottom: 1px solid #B9CDD9
        font-weight: 500

        
        &:focus
          outline: none

        &:after
          border: 3px solid transparent
          border-top-color: transparent
          border-top-style: solid
          border-top-width: 3px
          border-right-color: transparent
          border-right-style: solid
          border-right-width: 2px
          border-bottom-color: #0B3954
          border-bottom-width: 2px
          border-left-color: #0B3954
          border-left-width: 2px
          border-radius: 2px
          border-right: 0
          border-right-color: #0B3954
          border-right-width: 0px
          border-top: 0
          border-top-color: #0B3954
          border-top-width: 0px
          content: " "
          display: block
          height: 0.625em
          margin-top: -0.4375em
          pointer-events: none
          position: absolute
          top: 50%
          transform: rotate(-45deg)
          transform-origin: center
          width: 0.625em
          right: 0
          z-index: 999

        &.active::after 
          transform: rotate(135deg)

        &:hover 
          color: #E9856D

  .underlayer
    position: absolute
    background: #E4EFF6
    z-index: 1
    width: 25%
    min-height: 100vh
    top: 0px
    right: 0px

    @include mobile
      display: none

  .sidebar
    z-index: 2

.article-cards
  position: relative
  overflow: hidden
  @include transition(all)
  width: 310px
  margin: 8px 

  a
    position: absolute
    z-index: 10
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    display: block

  .cover
    height: 170px
    position: relative
    z-index: 1

    img
      object-fit: cover
      width: 100%
      height: 100%

  .inner
    padding: 16px 16px 16px 16px
    background: white
    position: relative
    z-index: 1

    .author-image
      position: absolute
      width: 40px
      height: 40px
      left: 16px
      top: -20px
      border-radius: 50%
      padding: 2px
      border: 1px solid $primary
      background: white

      img
        width: 40px
        width: 40px
        border-radius: 50%

    .title
      margin-bottom: 0.6rem
      @include transition(color)
      font-size: 18px

    .author-name
      font-size: 14px
      color: $black-light
      @include transition(color)

  &:hover
    transform: scale(1.02)

    .inner
      background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
      color: white

      .title, .author-name
        color: white

.article-cards-large
  @include shadow
  position: relative
  overflow: hidden
  @include transition(all)
  width: 100%
  margin: 8px 

  a
    position: absolute
    z-index: 10
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    display: block

  .cover
    height: 100%
    width: 50%
    position: relative
    z-index: 1
    display: inline-block

    img
      object-fit: cover
      width: 100%
      height: 100%

  .inner
    background: white
    padding: 30px 30px 30px 30px
    position: absolute
    top: 0
    right: 0
    z-index: 1
    display: inline-block
    height: 100%
    width: 50%

    .title
      margin-bottom: 0.6rem
      @include transition(color)
      font-size: 24px
      font-weight: 500
    
    .author-info
      position: absolute
      bottom: 30px
      .author-image
        position: relative
        width: 48px
        height: 48px
        background: white
        display: inline-block

        img
          width: 48px
          width: 48px
      .info
        display: inline-block
        .author-name
          font-size: 14px
          font-weight: 500
          color: $black-light
          @include transition(color)
          display: block
          
        
        .created-at
          font-size: 14px
          font-weight: 500
          color: $black-light
          @include transition(color)
          display: block
          

  &:hover
    transform: scale(1.02)

    .inner
      background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
      color: white

      .title
        color: white
      
      .info
        .author-name, .created-at
          color: white
  
  @include mobile
    position: relative
    overflow: hidden
    @include transition(all)
    width: 310px
    margin: 8px 

    a
      position: absolute
      z-index: 10
      top: 0px
      left: 0px
      width: 100%
      height: 100%
      display: block

    .cover
      height: 170px
      position: relative
      z-index: 1
      width: 100%
      height: 60%

      img
        object-fit: cover
        width: 100%
        height: 100%

    .inner
      padding: 16px 16px 16px 16px
      background: white
      position: relative
      z-index: 1
      width: 100%
      height: 100%

      .title
        margin-bottom: 0.6rem
        @include transition(color)
        font-size: 18px
        position: relative
      
      .author-info
        position: relative
        bottom: 0
        .author-image
          display: none

        .author-name
          font-size: 14px
          color: $black-light
          @include transition(color)

