button.button
  padding: 14px 30px 10px 30px

.button
  border-radius: 10px
  @include transition(all)

  &.is-small
    border-radius: 8px
    font-weight: 500
    padding: 7px 15px 5px 15px

    &.is-primary:hover
      background: $contrast

  &[data-icon]
    padding: 12px 20px 10px 48px
    height: auto
    display: inline-block

    i, span
      vertical-align: middle

    i
      font-size: 22px
      position: absolute
      left: 16px
      top: 11px
      margin: 0
      padding: 0

.button.is-default
  @include shadow

  &:hover
    background: $primary
    color: white

.button.is-contrast
  border: solid 1px $contrast
  background: $contrast
  color: white

  &:hover
    background: $primary
    color: white
    border-color: $primary

.button.is-expandable
  border: 0
  box-sizing: border-box
  background: #F3F8FB
  font-family: inherit
  font-style: normal
  font-weight: 500
  font-size: 16px
  letter-spacing: 0.03em
  color: #0D9DA4
  padding: 2px
  text-decoration-line: underline

  &:after

    @include icon
    content: "\e9c2"
    box-sizing: border-box
    position: absolute
    left: calc(100%)
    top: calc(50% - (1em / 2))
    height: 1em
    width: 1em
    color: white !important
    font-size: 16px
    color: #0D9DA4 !important
    margin-left: 4px

.button.is-expandable-up
  border: 0
  box-sizing: border-box
  background: #F3F8FB
  font-family: inherit
  font-style: normal
  font-weight: 500
  font-size: 16px
  letter-spacing: 0.03em
  color: #0D9DA4
  padding: 2px
  text-decoration-line: underline

  &:after

    @include icon
    content: "\e9c2"
    box-sizing: border-box
    position: absolute
    left: calc(100%)
    top: 0
    height: 1em
    width: 1em
    color: white !important
    font-size: 16px
    color: #0D9DA4 !important
    transform: rotate(180deg)
    margin-left: 4px

.button.is-contact
  width: 100%
  border: 1px solid #C8D6DF
  box-sizing: border-box
  border-radius: 50px
  background: #F3F8FB
  &[data-icon]
    padding: 14px 20px 10px 48px
    height: auto
    display: inline-block

    i, span
      vertical-align: middle

    i
      font-size: 22px
      position: absolute
      left: 16px
      top: 16px
      margin: 0
      padding: 0

  &:hover
    background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
    border-color: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
    color: white

    i
      color: white

.button.is-show-phone
  width: 146px
  height: 32px
  border: 1px solid #C8D6DF
  border-radius: 50px

  &:hover
    background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
    border-color: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
    color: white

.button.is-booking
  border-radius: 0px
  border-bottom: 1px solid #C8D6DF
  box-sizing: border-box
  margin: 0px

a.inline-icon
  color: $black
  font-size: 14px
  display: inline-block

  span
    text-decoration: underline

  i
    color: $grey
    padding-right: 0px
    text-decoration: none !important

  &:hover
    color: $primary

    span
      text-decoration: none

.action-button
  background: #fff

  border-radius: 25px
  display: inline-block
  margin-bottom: 0.5rem
  padding: 12px 20px 10px 40px
  position: relative
  margin-right: 5px
  font-size: 14px
  cursor: pointer
  font-weight: 700
  outline: none
  position: relative
  color: #55778A
  opacity: 1
  border: 1px solid #C8D6DF

  font-family: inherit
  font-style: normal
  font-weight: bold
  font-size: 14px
  letter-spacing: 0.03em
  text-transform: uppercase

  @include mobile
    width: 100%

  &:after
    @include icon
    position: absolute
    border: none
    width: 35px
    height: 35px
    line-height: 32px
    text-align: center
    font-size: 20px
    left: 8px
    top: 8px
    content: "\e940"

  &.reverse
    padding: 12px 20px 10px 50px

    &:after
      right: auto
      left: 4px

  &[data-small-icon='info']
    &:after
      content: "\e909"

  &[data-small-icon='edit']
    &:after
      content: "\e935"

  &[data-small-icon='add']
    &:after
      content: "\e908"

  &[data-small-icon='camera']
    &:after
      content: "\e9a3"

  &[data-small-icon='video']
    &:after
      content: "\e946"

  &[data-small-icon='dragndrop']
    &:after
      content: "\e9c3"

  &[data-small-icon='copy-text']
    &:after
      content: "\e9cb"

  &[data-small-icon='accept']
    &:after
      content: "\e980"

  &[data-small-icon='delete']
    &:after
      content: "\e933"

  &[data-small-icon='disable']
    &:after
      content: "\e910"

  &[data-small-icon='clock']
    text-align: center
    &:after
      content: "\e932"

  &[data-small-icon='more']
    text-align: center
    &:after
      content: "\e9b7"

  &[data-small-icon='file']
    text-align: center
    &:after
      content: "\e9c6"

button.action-button
  padding: 15px 30px 13px 50px

.helpful
  position: relative
  width: 120px
  height: 32px
  border: 1px solid #C8D6DF
  border-radius: 50px

  &:hover
    border-color: #EDB364

  .helpful-count
    font-weight: normal
    position: absolute
    right: 12px

  a
    display: inline-block
    font-family: inherit
    font-style: normal
    font-weight: normal
    font-size: 14px
    color: #0B3954
    padding: 4px 16px 8px 16px
    @include transition(all)

    i, span
      vertical-align: middle
      color: #0B3954

    &:hover
      border-color: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      border-radius: 50px
      background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      background-size: 100%
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      i:before, span
        color: white

    &.clicked
      border-color: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      border-radius: 50px
      background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      background-size: 100%
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      i:before, span
        color: white
        font-weight: 600

.btn-google-auth
  background-image: url("~/public/images/btn_google_signin_dark_normal_web@2x.png")
  width: 191px
  height: 46px
  display: block
  background-repeat: no-repeat
  background-size: contain

.btn-google-auth:hover
  background-image: url("~/public/images/btn_google_signin_dark_pressed_web@2x.png")

.btn-primary
  background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%) !important
  border-radius: 100px !important
  color: #fff !important
  font-weight: 700 !important
  font-size: 14px !important
  font-family: inherit !important
  border: 1px solid transparent !important
  padding: 15px 30px 15px 30px !important
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box
  text-transform: uppercase
  height: auto

  &[data-small-icon]
    padding-left: 50px !important

    &:hover
      padding-left: 50px !important

  &[data-icon]
    padding-left: 50px !important

    &:hover
      padding-left: 50px !important

  &:hover
    background: linear-gradient(215.91deg, #EDB364 12.73%, #E96D74 85.52%) !important

  &.working
    background: linear-gradient(225.47deg, rgba(13, 157, 164, 0.37) 1%, rgba(11, 57, 84, 0.72) 113.27%) !important
    color: transparent !important
    border-color: rgba(13, 157, 164, 0.37) !important
    cursor: not-allowed

    &:after
      @include icon
      content: "\e912"
      box-sizing: border-box
      position: absolute
      animation: spinner 0.8s linear infinite
      left: calc(50% - (1em / 2))
      top: calc(50% - (1em / 2))
      height: 1em
      width: 1em
      color: white !important
      font-size: 25px
      color: #fff !important

  &.clicked
    background: linear-gradient(225.47deg, rgba(13, 157, 164, 0.37) 1%, rgba(11, 57, 84, 0.72) 113.27%) !important
    color: transparent !important
    border-color: rgba(13, 157, 164, 0.37) !important
    border-radius: 50px
    background-image: none
    background-size: 100%
    -webkit-background-clip: text
    -webkit-text-fill-color: white
    cursor: not-allowed

  &.saved
    background: linear-gradient(225.47deg, rgba(13, 157, 164, 0.37) 1%, rgba(11, 57, 84, 0.72) 113.27%) !important
    color: transparent !important
    border-color: rgba(13, 157, 164, 0.37) !important
    border-radius: 50px
    background-image: none
    background-size: 100%
    -webkit-background-clip: text
    -webkit-text-fill-color: white
    cursor: not-allowed

  &.small
    text-align: center !important
    padding: 8px 24px !important
    width: 150px !important

    &:hover
      text-align: center !important
      padding: 8px 24px !important
      width: 150px !important

.btn-primary:focus, .btn-primary:active
  border: none
  color: #0D9DA4

.btn-secondary
  background: linear-gradient(227.66deg, #3EB1B6 0%, #3D6B7E 100%)
  border-radius: 100px !important
  color: #fff !important
  font-weight: 700 !important
  font-size: 17px !important
  font-family: inherit !important
  border: 2px solid rgba(62, 177, 182, 1)

.btn-secondary:hover
  background: #fff
  color: #0B3954
  border: 2px solid rgba(62, 177, 182, 1)

.button:focus:not(:active), .button.is-focused:not(:active)
  box-shadow: none !important

.btn-tertiary
  background: transparent !important
  border: 1px solid #C8D6DF !important
  border-radius: 50px !important
  padding: 15px 15px 15px 15px !important
  color: #0B3954 !important
  width: 100% !important
  margin: 0 !important
  outline: 0 !important
  box-shadow: none !important
  text-transform: uppercase !important
  font-weight: 700 !important
  height: auto !important
  text-align: center !important

  &[data-small-icon]
    padding-left: 50px !important

    &:hover
      padding-left: 50px !important

  &[data-icon]
    padding-left: 50px !important

    &:hover
      padding-left: 50px !important

  &:hover
    background: transparent !important
    border: 1px solid #0B3954 !important
    color: #0B3954 !important

  &.working
    cursor: not-allowed
    color: transparent !important
    &:after

      @include icon
      content: "\e912"
      box-sizing: border-box
      position: absolute
      animation: spinner 0.8s linear infinite
      left: calc(50% - (1em / 2))
      top: calc(50% - (1em / 2))
      height: 1em
      width: 1em
      color: white !important
      font-size: 25px
      color: #0B3954 !important

  &.small
    width: 90% !important
    min-width: 195px !important
    max-width: 195px !important
    height: 40px !important
    font-size: 12px
    font-weight: normal
    padding: 10px 10px 10px 10px !important

    @include mobile
      max-width: 100% !important
      width: 100% !important

    &[data-small-icon]
      padding-left: 30px !important

      i
        font-size: 12px
        position: absolute
        left: 16px
        top: 14px
        margin: 0
        padding: 0

        @include mobile
          font-size: 16px
          left: 20%
          top: 12px

      &:hover
        padding-left: 30px !important

    &[data-icon]
      padding-left: 30px !important

      i
        font-size: 12px
        left: 16px
        top: 14px
        margin: 0
        padding: 0

        @include mobile
          font-size: 16px
          left: 20%
          top: 12px

      &:hover
        padding-left: 30px !important

.btn-quaternary
  background: transparent !important
  border: 1px solid #F58C72 !important
  border-radius: 50px !important
  padding: 15px 30px !important
  font-family: inherit
  font-style: normal
  font-weight: bold
  font-size: 12px
  line-height: 16px
  text-align: center
  letter-spacing: 0.03em
  text-transform: uppercase
  color: #F58C72 !important

  &:hover
    cursor: pointer

.btn-endorsement
  background: transparent !important
  border: 0
  border-radius: 50%
  height: 35px !important
  width: 35px !important
  color: #55778A

  &:after
    @include icon
    position: relative
    border: none
    width: 35px
    height: 35px
    line-height: 32px
    text-align: center
    font-size: 36px
    left: -8px
    top: 2px
    content: "\e9a7"
    color: #55778A

  &:hover
    cursor: pointer

    &:after
      color: #0B3954

.btn-primary-teal
  border: none !important
  padding: 12px 16px !important
  background: #0D9DA4 !important
  color: #fff !important
  font-size: 16px !important
  font-weight: 700 !important
  text-align: center !important
  letter-spacing: 0.03em !important
  height: fit-content !important
  min-width: 130px !important
  border-radius: 4px !important

  &:hover
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0D9DA4 !important

.btn-secondary-grey
  border: 1px solid !important
  padding: 12px 16px !important
  background: transparent !important
  color:  #0B3954 !important
  font-size: 16px !important
  font-weight: 700 !important
  text-align: center !important
  letter-spacing: 0.03em !important
  height: fit-content !important
  min-width: 130px !important
  border-radius: 4px !important

  &:hover
    background: #E4EFF6

.btn-secondary-completed
  border: transparent !important
  border-radius: 50px !important
  padding: 12px 16px !important
  background:  #F3F8FB !important
  color:  #0D9DA4 !important
  font-size: 16px !important
  font-weight: 700 !important
  text-align: center !important
  letter-spacing: 0.03em !important
  height: fit-content !important

  &:hover
    cursor: default
