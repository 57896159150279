body.public.about,
body.public.download_app
  .container
    @include mobile
      margin-left: 15px
      margin-right: 15px

    @include tablet-only
      margin-left: 50px
      margin-right: 50px

    @include from($widescreen)
      max-width: 1100px

  .hero-section
    position: relative
    margin-bottom: 7rem

    @include mobile
      margin-bottom: 4rem

    @include from($widescreen)
      margin-bottom: 9rem

    span
      background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    .image
      margin-top: 3rem

      @include mobile
        position: relative
        margin: 3rem 15px 0 15px
        top: auto
        width: auto
        right: auto

      img
        width: 100%



    .container
      position: relative
      z-index: 3



    h1
      margin-top: 4rem
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 46px
      line-height: 58px


      @include mobile
        font-size: 32px
        text-shadow: none
        line-height: 40px
        letter-spacing: 0rem
        padding-right: 10%

    h2
      @include homepage-standard
      margin-top: 3rem
      font-size: 16px
      line-height: 32px
      padding-right: 26%

      @include mobile
        font-size: 13px
        margin-top: 1rem
        line-height: 22px

    .action-call
      text-align: left
      margin-top: 3rem

      @include mobile
        margin-top: 2rem

      @include from($tablet)
        a.button.is-default
          padding: 42px 80px 39px 80px


  .founder-section
    padding: 8rem 0 8rem 0
    margin-bottom: 6rem
    position: relative

    @include mobile
      padding: 4rem 0
      margin-bottom: 0

      .container
        margin-left: 0
        margin-right: 0


    .column
      z-index: 5
      position: relative

    .image img
      max-width: 400px

    h4
      font-size: 21px
      font-weight: 700
      line-height: 32px
      color: #0B3954



    h3
      margin: 0 15px 2rem 15px

      @include from($tablet)
        display: none

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 67%
      height: 100%
      top: 0px
      right: 0px

      @include mobile
        display: none

    .from-founder
      img
        width: 150px
        margin-top: 25px

  p.bio
    font-size: 14px
    line-height: 18px
    color: #0D9DA4
    margin-bottom: 2rem

  h2
    font-size: 40px

    span
      background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

  .questions-answers-section
    padding-bottom: 8rem

    @include mobile
      padding-bottom: 3rem

    h3
      text-align: left
      margin-bottom: 3rem

      @include mobile
        margin-bottom: 0

    .action-call
      margin-top: 2rem

      a
        width: 208px
        padding-left: 0
        padding-right: 0
        margin: 0 6px

      @include mobile
        a
          width: 100%
          margin-bottom: 10px

    .question-answer
      padding-bottom: 15px
      border-bottom: 2px solid #e5ebef

      article.media
        figure.media-left
          padding-right: 10px

          img
            width: 75px
            height: 75px

            @include mobile
              width: 48px
              height: 48px

        .media-content
          .content
            .question
              @include homepage-heading
              font-weight: 600
              font-size: 18px
              line-height: 24px

              a
                color: $homepage-primary-color

                &:hover
                  color: $homepage-hover-color

            .answer
              margin-top: 10px
              font-size: 14px
              font-weight: 300
              line-height: 18px
              color: #55778A

  .marketing-section
    position: relative
    padding: 10rem 0

    @include mobile
      padding: 3rem 0 4rem 0

    .underlayer
      position: absolute
      z-index: 1
      left: 0px
      top: 0px
      background: linear-gradient(211.38deg, #EDB364 12.35%, #E9856D 92.86%)
      width: 80%
      height: 100%

      @include touch
        width: 100%

    .image
      position: absolute
      width: 30%
      right: 55px
      top: 4rem
      z-index: 3
      max-width: 542px
      height: 745px

      @include mobile
        display: none

      @include fullhd
        right: 6%

    .container
      position: relative
      z-index: 2

    h3
      margin-bottom: 2rem

    .description
      font-size: 18px
      margin: 2rem 0 1.5rem 0

    .mobile-image
      @include from($tablet)
        display: none

    .action-call
      text-align: left
      margin-top: 3rem

      @include mobile
        text-align: center

      a.button.is-transparent
        color: white
        border-color: white

        &:hover
          background: white
          color: #E9856D

    &.a
      h3, .description, ul li
        color: white

      ul li i
        color: white

        &.icon-bookmark::before
          color: white





    &.b
      margin-top: 0rem

      .toggle-wrapper
        justify-content: center

        #tabs
          border: 1px solid #B9CDD9
          border-radius: 50px

          .bullet-points
            margin-bottom: 0px
            display: flex
            justify-content: center
            flex-wrap: nowrap
            margin-bottom: 0
            padding: 5px

          li
            position: relative
            z-index: 99
            margin-bottom: 0px
            padding: 1rem
            margin-right: 0
            white-space: normal

            @include mobile
              width: 125px

            &:hover
              cursor: pointer

          li.is-active
            color: #E9856D
            background-color: #fff
            padding: 1rem
            border-radius: 50px


      #tab-content .columns.sections
        display: none

      #tab-content .columns.sections.is-active
        display: flex

        @include mobile
          display: block




      @include mobile
        margin-top: 0
        background: #E4EFF6

      @include from($desktop)
        margin-top: 0rem

      .square
        width: 100%
        border-radius: 0
        padding: 3rem 0


      ul.accordion
        list-style: none
        padding: 0

        .inner
          overflow: hidden
          display: none
          position: relative
          z-index: 2
          padding: 0.5em 1em

          li

            &::marker
              content: "\2022"
              color: #E9856D
              position: absolute
              left: 0
              font-size: 1.2em
              line-height: 24px
              height: 24px


            a
              color: #0B3954
              margin-left: 1em
              display: block
              padding-top: 6px
              padding-bottom: 6px

              &:hover
                color: #E9856D

            &.last

              a
                color: #E9856D
                margin-left: 0
                text-transform: uppercase
                font-size: 12px
                font-weight: 600

              &::marker
                content: " "

          &.show
            border-radius: 0.15em
            margin-top: 3px


        li
          margin: .5em 0

          a.toggle
            width: 100%
            display: block
            background: transparent
            color: #0B3954
            padding: .75em
            border-radius: 0em
            transition: background .3s ease
            position: relative
            z-index: 3
            border-bottom: 1px solid #B9CDD9
            font-weight: 500


            &:focus
              outline: none

            &:after
              border-radius: 2px
              border: 2px solid #0B3954
              border-top-width: 0
              border-right-width: 0
              content: " "
              display: block
              height: 0.625em
              margin-top: -0.4375em
              pointer-events: none
              position: absolute
              top: 50%
              transform: rotate(-45deg)
              transform-origin: center
              width: 0.625em
              right: 0
              z-index: 999

            &.active::after
              transform: rotate(135deg)

            &:hover
              color: #E9856D




  .quiz-section
    position: relative
    padding-bottom: 5rem

    @include mobile
      padding-bottom: 3rem

    @include from($widescreen)
      padding-bottom: 15rem

    .image
      position: absolute
      width: 40%
      left: 55px
      z-index: 3
      max-width: 654px
      max-height: 698px

      @include mobile
        display: none

      @include fullhd
        left: 6%

    .container
      position: relative
      z-index: 2

    h3
      margin-bottom: 2rem
      margin-top: 4rem

    .mobile-image
      margin-bottom: 1rem

      @include from($desktop)
        display: none

    .action-call
      text-align: left
      margin-top: 3rem

      @include mobile
        text-align: center
