.filestack-upload
  position: relative

  button.profile-photo
    position: absolute
    top: 50px
    left: 180px

    @include mobile
      position: relative
      top: 0
      left: 0

  button.video
    position: absolute
    top: 70px
    left: 280px

    @include mobile
      position: relative
      top: 0
      left: 0

  .image-preview  
    display: flex
    justify-content: center
    align-items: center
    z-index: 1

  .spinner-container
    display: none
    justify-content: center
    align-items: center
    position: absolute
    z-index: 99
    top: 0

  .image-overlay
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    cursor: pointer
    z-index: 90

    i
      font-size: 30px

  &.with-photo
    .image-overlay i
      opacity: 0

    .image-overlay:hover
      background: rgba($primary, 0.6)

      i
        color: white
        opacity: 1

  &.without-photo
    .image-overlay
      border: 1px solid $grey-light

      i
        color: $grey
        opacity: 1

      &:hover
        border: 1px dashed $primary

        i
          color: $primary

  &.profile-photo
    .image-preview  
      width: 160px
      height: 160px

    .spinner-container
      width: 160px
      height: 160px

    .image-overlay
      width: 160px
      height: 160px

  &.header-background
    .image-preview  
      width: 656px
      height: 143px

      img
        width: 100%
        height: 100%
        object-fit: cover
      
      @include mobile
        width: auto
        height: auto
        min-width: 100px
        min-height: 50px

        img
          width: auto
          height: auto
          min-width: 100px
          min-height: 50px

    .spinner-container
      width: 656px
      height: 143px

    .image-overlay
      width: 656px
      height: 143px

      @include mobile
        width: auto
        height: auto

  &.gallery-images
    .images-preview
      display: flex
      flex-wrap: wrap

      .image-container
        position: relative

        .image-preview  
          width: 80px
          height: 80px
          margin-right: 10px
          margin-bottom: 10px
          display: inline-block

          img
            width: 100%
            height: 100%
            object-fit: cover

        .spinner-container
          width: 80px
          height: 80px

        .delete-overlay
          width: 80px
          height: 80px
          cursor: default
          display: flex
          justify-content: center
          align-items: center
          position: absolute
          top: 0
          z-index: 90

          i
            font-size: 30px
            opacity: 0
          
        .delete-overlay:hover
            background: rgba($primary, 0.6)

            i
              opacity: 1
            
            i:before
              color: white

        .image-overlay
          position: relative
          width: 80px
          height: 80px
          border: 1px solid $grey-light

          i
            color: $grey
            opacity: 1

          &:hover
            border: 1px dashed $primary

            i
              color: $primary

  &.credential-document
    .image-container
      position: relative
      display: none

      .image-preview  
        width: 80px
        height: 80px
        display: inline-block

        img
          width: 100%
          height: 100%
          object-fit: cover

      .delete-overlay
        width: 80px
        height: 80px
        cursor: default
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        top: 0
        z-index: 90

        i
          font-size: 30px
          opacity: 0
        
      .delete-overlay:hover
          background: rgba($primary, 0.6)

          i
            opacity: 1
            cursor: pointer
          
          i:before
            color: white

  &.practice-video
    &.with-photo
      .image-overlay
        display: none

    .image-preview  
      width: 260px
      height: 200px

      video
        height: 200px

    .spinner-container
      width: 260px
      height: 200px

    .image-overlay
      width: 260px
      height: 200px