body.instant-action
  background: #F3F8FB
  min-height: 100vh
  padding: 0

  .container.review-flow
    margin-top: 30px

  .logo
    background: #fff
    margin-bottom: 0px
    border-bottom: 1px solid #C8D6DF
    a
      display: flex

    img
      width: 120px
      margin: 24px auto

  i
    font-size: 24px
    color: #55778A
    top: 4px
    position: relative

  h1
    margin-top: 1rem
    margin-bottom: 0.5rem
    font-weight: bold
    font-size: 21px
    line-height: 28px
    letter-spacing: -0.02em
    color: #0B3954

  p.instruction
    font-weight: normal
    font-size: 16px
    line-height: 21px
    color: #55778A
    border-bottom: 1px solid $grey-light
    padding-bottom: 1.5rem
    margin-bottom: 2rem

  .section-title
    font-weight: bold
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.03em
    text-transform: uppercase
    color: #55778A
    margin-bottom: 1rem
    text-transform: uppercase
    margin: 12px

    &.status
      margin-left: 0

  .session-details
    position: relative
    padding-left: 120px



    @include mobile
      padding-left: 75px

    .photo
      position: absolute
      width: 100px
      height: 10px
      left: 0px
      top: 0px

      img
        border-radius: 16px

      @include mobile
        width: 60px
        height: 60px

    .practitioner-name
      @include h4style
      font-size: 22px
      margin-bottom: 12px

    .metadata
      .item
        position: relative
        padding-left: 27px
        font-size: 14px
        margin-bottom: 10px

        i
          position: absolute
          top: -2px
          left: 0px
          font-size: 20px

  .section-details
    position: relative
    padding-left: 40px
    margin-bottom: 1rem


    img
      position: absolute
      left: 0px
      top: 0px
      width: 48px
      height: 48px
      border-radius: 50%

    i
      position: absolute
      left: 0px
      top: 0px
      width: 48px
      height: 48px
      border-radius: 8px
      line-height: 48px
      text-align: center
      font-size: 24px

      &.icon-accept
        background: $success
        color: white

      &.icon-warning
        background: $danger
        color: white

    .name
      font-weight: 500
      font-size: 16px
      line-height: 24px
      letter-spacing: -0.01em
      color: #0B3954

    .description
      font-weight: normal
      font-size: 14px
      line-height: 22px
      letter-spacing: -0.01em
      color: #55778A

    &.status
      padding-left: 60px


  .action-bar
    margin-top: 2rem

    a.action-button
      width: 100%
      padding: 15px 20px 12px 58px
      text-align: center
      font-weight: bold
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.03em
      text-transform: uppercase
      color: #0B3954

      &.reverse:after
        left: 38%
        top: 7px
        font-weight: bold

      &[data-action='accept']
        border: 1px solid #C8D6DF

      &[data-action='decline']
        border: 1px solid #C8D6DF

      &.offer-other-times
        @include mobile
          &.reverse:after
            left: 25%


    .action-item
      h6
        font-weight: 500
        font-size: 16px
        line-height: 24px
        letter-spacing: -0.01em
        color: #0B3954

      p
        font-weight: normal
        font-size: 16px
        line-height: 21px
        color: #55778A


  form.simple_form
    .rating-selector
      display: inline-block
      font-size: 30px
      margin-top: -8px
      margin-bottom: 0.5rem
      color: $primary

      i
        cursor: pointer

      &:hover
        color: $contrast

    .decline-option-card
      padding: 12px
      border-radius: 12px
      cursor: pointer
      @include transition(all)
      margin-bottom: 6px
      box-shadow: none

      .name
        margin-bottom: 8px
        font-size: 16px

      .description
        font-size: 14px
        color: $black-light

      &:hover
        border: 1px solid $primary
        background: rgba($primary, 0.1)

      &.selected
        background: $primary
        border-color: $primary

        .name, .description
          color: white

  .additional-times-modal
    .modal
      .box
        min-height: 800px
        .calendar-times
          max-height: 440px
          overflow-y: hidden

        h3
          font-size: 24px
          line-height: 32px
          letter-spacing: -0.02em
          color: #0B3954
          font-weight: bold

        .subtitle
          font-size: 16px
          line-height: 24px
          color: #55778A

        h5
          font-weight: 500
          font-size: 16px
          line-height: 18px
          letter-spacing: -0.01em
          color: #0B3954
          margin-bottom: 4px

        span
          font-size: 14px
          line-height: 22px
          letter-spacing: -0.01em
          color: #0B3954

        p
          font-size: 14px
          line-height: 22px
          letter-spacing: -0.01em
          color: #55778A

        span.date
          font-weight: 500
          font-size: 14px
          line-height: 24px
          letter-spacing: -0.01em
          color: #0B3954
          margin-bottom: 8px


        .btn-primary
          width: 100%


        .time-slot
          width: 13%
          display: inline-block
          text-align: center
          padding: 0 4px 0 0
          a
            display: block
            padding: 7px 5px 5px 5px
            box-shadow: none
            font-family: inherit
            font-style: normal
            font-weight: 500
            font-size: 12px
            letter-spacing: -0.02em
            color: #0B3954
            background: #F3F8FB
            margin-bottom: 10px
            text-transform: lowercase

            &:hover, &.selected
              background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
              color: white
            @include mobile
              font-size: 11px

          .inactive-link
            pointer-events: none
            cursor: default

          @include mobile
            width: 24%

      .is-expandable
        background: transparent
        margin-bottom: 10px
        padding-bottom: 10px


      .is-button
        position: absolute
        bottom: 0
        left: 0

        @include mobile
          position: relative

  .star-radiobutton
    display: inline-block
    min-width: 75px
    margin-bottom: 24px
    input[type="radio"]
      display: none

  .star
    font-size: 48px

    &:hover
      cursor: pointer

    &:before
      color: #C8D6DF

  .star-filled
    &:before
      background: linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    &:hover
      cursor: pointer

  .rating-keyword
    font-weight: 500
    font-size: 18px
    line-height: 24px
    text-align: center
    letter-spacing: -0.01em
    color: #0B3954
    margin-bottom: 160px

  .review-min
    float: right
    color: #55778A

  .btn-primary
    padding: 20px 62px !important

  .btn-tertiary
    border: 0 !important
    width: auto !important

  .profile_photo
    max-height: 80px
    max-width: 80px

  .header
    font-weight: bold
    font-size: 24px
    line-height: 32px
    text-align: center
    letter-spacing: -0.01em
    color: #0B3954
    margin-bottom: 8px

  .subheader
    font-weight: normal
    font-size: 16px
    line-height: 24px
    text-align: center
    color: #55778A
    margin-bottom: 20px


  .scroll-area
    max-height: 400px
    overflow-y: scroll
    padding: 20px
    margin-bottom: 8px
    &::-webkit-scrollbar
      width: 4px

    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5)

    &::-webkit-scrollbar-thumb
      background-color: darkgrey
      outline: 1px solid #C8D6DF

  textarea
    margin-bottom: 8px

  .bullet-nav
    display: block
    margin-top: 16px

    .bullet-filled
      color: #0B3954
      display: inline-block
      padding: 6px

    .bullet-empty
      color: #C8D6DF
      display: inline-block
      padding: 6px

  .review-account-creation
    margin-top: 32px

    .create-title
      font-family: 'Manrope', serif
      font-size: 24px
      font-weight: 700
      color: #0B3954
      margin-bottom: 12px
      line-height: 32px

    .create-subtitle
      font-size: 18px
      line-height: 28px
      font-weight: 400

    .create-form
      padding: 10px 40px

      .input-row
        margin-bottom: 16px

  .intl-tel-input
    width: 100%

    input
      margin-bottom: 16px
      border: 1px solid #c8d6df
      background: white
      padding: 14px 16px
      width: 100%
      margin: 0
      outline: 0
      box-shadow: none
      border-radius: 0px
      height: auto
      font-size: 16px

@media (max-width: 768px)
  body.instant-action
    .container
      padding-left: 30px
      padding-right: 30px

    .star-radiobutton
      min-width: 60px

    .profile_photo.header
      max-height: 50px
      max-width: 50px
      float: left

    .pro-name
      font-size: 18px
      margin-bottom: 0


