.card.transaction
  padding: 15px
  position: relative
  margin-bottom: 10px

  span.badge
    position: absolute
    right: 10px
    top: 10px
    font-size: 10px
    font-weight: 600

    &[data-state='cancelled']
      background: #bfbfbf

  .name
    @include h5style
    margin-top: 4px
    font-weight: 300

    @include mobile
      padding-right: 150px

  .amount
    position: absolute
    bottom: 10px
    right: 15px
    font-size: 20px
    font-weight: 500

    @include mobile
      position: relative
      right: auto
      bottom: auto

    &.refund-settled
      color: $danger

    &.charge-settled
      color: $black

    &.hold-settled
      color: #bfbfbf

    &.refund-failed, &.charge-failed, &.hold-failed, &.hold-cancelled
      color: #bfbfbf
      text-decoration: line-through

  .meta-items
    margin-top: 0.75rem

    .meta-item
      position: relative
      padding-left: 30px
      display: inline-block
      margin-right: 10px
      padding: 5px 15px 5px 25px
      font-size: 14px
      color: $black-light

      i
        position: absolute
        font-size: 20px
        color: $contrast
        top: 3px
        left: 0px