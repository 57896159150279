.pac-target-input
  border: 1px solid rgb(var(--color-gray))
  padding: 0.375rem 0.75rem
  border-radius: 0.5rem
  width: 100%

  &:hover
    border-color: rgb(var(--color-black))
    border-left: 1px solid rgb(var(--color-black)) !important

  &:focus
    outline: none
    border-color: rgb(var(--color-black))
    border-left: 1px solid rgb(var(--color-black)) !important
    --tw-ring-opacity: 1 !important
    --tw-ring-color: rgb(var(--color-blue) / var(--tw-ring-opacity)) !important
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important
    box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000 !important
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important

.pac-container
  width: 100%
  border-radius: 0.5rem
  border: 2px solid rgb(var(--color-gray))
  padding: 0.5rem 0
  margin-top: 4px
  z-index: 99999 !important
  box-shadow: none

  .pac-icon
    margin-right: 16px
    font-size: 16px
    color: rgb(var(--color-teal)) !important
    content: "\e9dd" !important
    background-image: url('~/app/assets/images/Pin.svg') !important
    background-position: center
    background-repeat: no-repeat
    background-size: cover

  .pac-icon:before
    color: #0D9DA4 !important

  .pac-item
    padding: 16px 0 16px 27px
    border: none
    font-size: 16px

  .pac-item:hover
    background-color: rgb(var(--color-ultra-light-gray))
    cursor: pointer

  .pac-item-query
    font-size: 16px

  .pac-matched
    font-size: 16px
    font-weight: 700
    color: rgb(var(--color-black))

.fsp-drop-pane__container
  background-color: white !important
  border-color: rgb(var(--color-gray)) !important
  border-radius: 0.5rem !important

  .fsp-drop-pane__icon
    background-image: url("~/public/images/icons/file_upload_icon.svg") !important
    background-position: 50% 50% !important
    background-color: rgb(var(--color-teal) / 0.12) !important
    height: 2.5rem !important
    width: 2.5rem !important
    border-radius: 100% !important
    padding: 0 !important
    margin-bottom: 10px !important

  .fsp-drop-pane__text
    color: rgb(var(--color-black)) !important
    font-size: 1rem !important
    font-weight: 400 !important
    line-height: 1.5 !important
