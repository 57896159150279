body.public.pro
  .container
    @include mobile
      margin-left: 0
      margin-right: 0
      width: auto

      .column
        padding: 0.75rem 16px

    @include tablet-only
      margin-left: 50px
      margin-right: 50px

    @include from($widescreen)
      max-width: 1100px

  .action-call
    .link
      text-decoration-line: underline
      display: inline-block

    &:hover
      a
        color: #0D9DA4
      span.i
        &::before
          color: #0D9DA4

  .hero-section
    position: relative
    height: 720px

    @include mobile
      margin-bottom: 0

    @media screen and (max-width: 320px)
      height: 690px

    @include from($widescreen)
      max-height: 900px
      min-height: 800px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 40%
      height: 90%
      top: 0px
      right: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 90%

    .image
      position: absolute
      z-index: 2
      right: 56px


      @include mobile
        position: relative
        margin: 3rem 15px 0 15px
        top: auto
        width: auto
        right: auto

      img
        max-height: 600px
        max-width: 500px

        @include from($widescreen)
          max-height: 732px
          max-width: 880px

    .container
      position: relative
      z-index: 3
      display: inline-block
      float: left
      width: 50%

      @include mobile
        top: 0
        width: auto
        display: block
        clear: both

      @include from($widescreen)



    .initial-adjective
      position: absolute
      word-wrap: normal
      margin-left: 8px
      background: -webkit-linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      @include mobile
        margin-left: 4px

    h1
      margin-top: 4rem
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 56px
      line-height: 70px
      color: #0B3954


      @include mobile
        font-size: 36px
        text-shadow: none
        line-height: 44px
        letter-spacing: -0.01em
        padding-right: 10%

      @include from($widescreen)
        margin-top: 25%

    h2
      @include homepage-standard
      margin-top: 20px
      font-size: 18px
      line-height: 28px
      padding-right: 5%
      color: #0B3954
      letter-spacing: 0.02em

      @include mobile
        font-size: 16px
        margin-top: 1rem
        line-height: 22px
        padding-right: 0px
        margin-bottom: 22px

    .action-call
      text-align: left
      margin-top: 3rem

      @include mobile
        margin-top: 2rem

      @include from($tablet)
        a.button.btn-primary
          padding: 33px 52px 31px 52px !important
          font-size: 17px !important
          line-height: 16px !important
          height: 64px !important

  .get-discovered-section
    position: relative
    height: 720px

    @media screen and (max-width: 428px)
      margin-bottom: 8rem

    @media screen and (max-width: 375px)
      margin-bottom: 0

    @include mobile
      min-height: 600px
      height: auto


    @include from($widescreen)
      max-height: 900px
      min-height: 800px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 30%
      height: 100%
      top: 0px
      left: 0px

      @include mobile
        height: 100%
        width: 100%
        top: -200px

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 2
      top: 88px
      width: auto
      float: left
      left: 15%


      @include mobile
        position: relative
        margin: 24px 4px 0 4px
        top: auto
        width: auto
        clear: both
        left: 0

      @include from($widescreen)
        top: 40px
        left: 20%

      img
        max-height: 550px
        max-width: 450px

        @include from($widescreen)
          max-height: 623px
          max-width: 512px
          height: auto
          width: auto


    .container
      position: relative
      z-index: 3
      float: right
      margin-left: 300px
      margin-right: 200px

      @include mobile
        top: 0
        width: auto
        clear: both
        margin-left: 0
        margin-right: 0
        display: block
        clear: both

      @include from($widescreen)
        margin-left: 0
        margin-right: 0
        width: 50%
        display: inline-block

      .column
        padding-left: 20%

        @include mobile
          padding-left: 16px


    .initial-adjective
      position: absolute
      word-wrap: normal
      background: -webkit-linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    h1
      margin-top: 4rem
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.03em
      font-size: 48px
      line-height: 56px
      color: #0B3954

      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        font-weight: bold
        margin-top: 0px

      @media screen and (max-width: 320px)
        margin-top: 0

      @include from($widescreen)
        margin-top: 40%


    h2
      @include homepage-standard
      margin-top: 24px
      font-size: 16px
      line-height: 26px
      padding-right: 26%
      color: #0B3954
      letter-spacing: 0.01em

      @include mobile
        font-size: 16px
        margin-top: 12px
        line-height: 22px
        padding-right: 16px
        margin-bottom: 16px


    .action-call
      text-align: left
      margin-top: 24px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #0B3954


      @include mobile
        margin-top: 0
        font-size: 14px
        line-height: 24px

      @include from($tablet)
        a.button.is-default
          padding: 33px 52px 31px 52px
          font-size: 17px
          line-height: 16px

      span.i
        font-weight: 600

  .expand-section
    position: relative
    height: 720px

    @include mobile
      margin-bottom: 0
      height: 700px

    @media screen and (max-width: 320px)
      height: 560px

    @include from($widescreen)
      max-height: 900px
      min-height: 700px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 40%
      height: 100%
      top: 0px
      right: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 4
      right: 20%
      top: -100px


      @include mobile
        position: relative
        margin: 40px 0 0 4px
        top: auto
        width: auto
        right: auto

      img
        max-height: 822px
        max-width: 518px

        @include mobile
          max-height: 100%
          max-width: 100%

        @include from($widescreen)
          max-height: 975px
          max-width: 615px

    .container
      position: relative
      z-index: 4
      float: left

      @include mobile
        width: auto
        display: block
        clear: both

      @include from($widescreen)
        width: 50%
        position: relative
        display: inline-block

    .initial-adjective
      position: absolute
      word-wrap: normal
      background: -webkit-linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      @include mobile
        margin-left: 4px

    h1
      margin-top: 206px
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 48px
      line-height: 56px
      color: #0B3954


      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-top: 44px
        margin-bottom: 12px

      @media screen and (max-width: 320px)
        margin-top: 0

      @include from($widescreen)
        margin-top: 40%

    h2
      @include homepage-standard
      margin-top: 24px
      font-size: 16px
      line-height: 26px
      padding-right: 26%
      color: #0B3954
      letter-spacing: 0.01em

      @include mobile
        font-size: 16px
        margin-top: 1rem
        line-height: 22px
        padding-right: 0
        margin-bottom: 16px

    .action-call
      text-align: left
      margin-top: 24px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #0B3954

      @include mobile
        margin-top: 0
        font-size: 16px
        line-height: 24px

      @include from($tablet)
        a.button.is-default
          padding: 33px 52px 31px 52px
          font-size: 17px
          line-height: 16px
      span.i
        font-weight: 600

  .get-matched-section
    background: linear-gradient(102.87deg, #0D9DA4 -1.03%, #0B3954 99.45%)
    position: relative
    margin-bottom: 1rem !important
    height: 720px
    z-index: 3

    @include mobile
      margin-bottom: 0 !important
      margin-top: 4rem
      height: 750px

    @media screen and (max-width: 320px)
      height: 700px

    @include from($widescreen)
      margin-bottom: 0 !important
      max-height: 900px
      min-height: 700px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 25%
      height: 105%
      top: 0px
      left: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 200%

    .image
      position: absolute
      z-index: 2
      top: 165px
      float: left


      @include mobile
        position: relative
        top: 40px
        width: auto
        right: auto
        clear: both
        margin-right: 16px

      @include from($widescreen)
        top: 170px
        left: 20%

      img
        max-height: 366px
        max-width: 528px

        @include mobile
          max-height: 100%
          max-width: 100%

        @include from($widescreen)
          max-height: 444px
          max-width: 640px


    .container
      position: relative
      z-index: 3
      width: 50%
      display: inline-block
      float: right

      @include mobile
        top: 0
        width: auto
        clear: both
        margin-right: 0
        margin-left: 0
        display: block
        float: none

    .column
      padding-left: 20%

      @include mobile
        padding-left: 16px

    .initial-adjective
      position: absolute
      word-wrap: normal
      background: -webkit-linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      margin-left: 8px

      @include mobile
        margin-left: 4px

    h1
      margin-top: 178px
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 48px
      line-height: 56px
      color: #FFFFFF


      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-top: 46px
        margin-bottom: 12px

      @include from($widescreen)
        position: relative
        margin: 0
        margin-top: 40%

    h2
      @include homepage-standard
      margin-top: 24px
      font-size: 16px
      line-height: 26px
      padding-right: 0
      color: #FFFFFF
      letter-spacing: 0.01em

      @include mobile
        font-size: 16px
        margin-top: 0
        line-height: 22px
        margin-bottom: 16px

    .action-call
      text-align: left
      margin-top: 24px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #FFFFFF

      a
        color: #FFFFFF

      @include mobile
        margin-top: 0
        font-size: 14px
        line-height: 24px

      @include from($tablet)
        a.button.is-default
          padding: 33px 52px 31px 52px
          font-size: 17px
          line-height: 16px
      span.i
        font-weight: 600
        color: #FFFFFF
        margin-left: 8px

        &:before
          color: #FFFFFF

  .streamline-booking-section
    position: relative
    height: 720px

    @include mobile
      margin-bottom: 4rem

    @media screen and (max-width: 320px)
      margin-bottom: 0

    @include from($widescreen)
      max-height: 900px
      min-height: 700px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 30%
      height: 100%
      top: 0px
      right: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 2
      right: 25%
      top: 160px


      @include mobile
        position: relative
        margin: 40px 16px 0 16px
        top: auto
        width: auto
        right: auto

      @include from($widescreen)
        top: 210px


      img
        max-height: 400px
        max-width: 565px

        @include from($widescreen)
          max-height: 422px
          max-width: 597px

        @include mobile
          max-height: 540px
          max-width: 100% !important
          width: auto
          height: auto

    .container
      position: relative
      z-index: 3
      width: 50%
      display: inline-block
      float: left

      @include mobile
        width: auto
        display: block
        clear: both

    .initial-adjective
      position: absolute
      word-wrap: normal
      background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      margin-left: 8px

      @include mobile
        margin-left: 4px

    h1
      margin-top: 200px
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 48px
      line-height: 56px
      color: #0B3954


      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-top: 56px
        margin-bottom: 12px

      @include from($widescreen)
        margin-top: 40%

    h2
      @include homepage-standard
      margin-top: 24px
      font-size: 16px
      line-height: 26px
      padding-right: 26%
      color: #0B3954
      letter-spacing: 0.01em

      @include mobile
        font-size: 16px
        margin-top: 0
        line-height: 22px
        padding-right: 0
        margin-bottom: 16px

    .action-call
      text-align: left
      margin-top: 24px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #0B3954

      @include mobile
        margin-top: 0
        font-size: 14px
        line-height: 24px

      @include from($tablet)
        a.button.is-default
          padding: 33px 52px 31px 52px
          font-size: 17px
          line-height: 16px
      span.i
        font-weight: 600

  .reviews-section
    position: relative
    height: 720px

    @include mobile
      background: #E4EFF6

    @media screen and (max-width: 428px)
      height: 720px

    @media screen and (max-width: 375px)
      height: 670px

    @media screen and (max-width: 320px)
      height: 630px

    @include from($widescreen)
      height: auto
      max-height: 900px
      min-height: 700px

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 30%
      height: 100%
      top: 0px
      left: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 2
      top: 38px
      float: left
      left: 15%

      @include mobile
        position: relative
        margin: 40px 15px 0 15px
        top: auto
        width: auto
        right: auto
        left: 0

      @include from($widescreen)
        top: 150px
        left: 25%

      img
        max-height: 420px
        max-width: 480px

        @include from($widescreen)
          height: auto
          width: auto
          max-height: 476px
          max-width: 544px


    .container
      position: relative
      z-index: 3
      display: inline-block
      width: 50%
      float: right

      @include mobile
        top: 0
        width: auto
        margin-left: 0
        display: block
        clear: both
        float: none

    .column
      padding-left: 20%

      @include mobile
        padding-left: 16px


    .initial-adjective
      position: absolute
      word-wrap: normal
      background: -webkit-linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    h1
      margin-top: 4rem
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.03em
      font-size: 48px
      line-height: 56px
      color: #0B3954


      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-bottom: 12px

      @media screen and (max-width: 428px)
        margin-top: 0

      @media screen and (max-width: 375px)
        margin-top: 30px

      @media screen and (max-width: 320px)
        margin-top: 46px

      @include from($widescreen)
        margin-top: 40%

    h2
      @include homepage-standard
      margin-top: 24px
      font-size: 16px
      line-height: 26px
      padding-right: 26%
      color: #0B3954
      letter-spacing: 0.01rem

      @include mobile
        font-size: 16px
        margin-top: 0
        line-height: 22px
        padding-right: 0
        margin-bottom: 16px

    .action-call
      text-align: left
      margin-top: 0
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #0B3954

      @include mobile
        margin-top: 16px
        font-size: 14px
        line-height: 24px

      @include from($tablet)
        a.button.is-default
          padding: 33px 52px 31px 52px
          font-size: 17px
          line-height: 16px
      span.i
        font-weight: 600

  .all-reviews-section
    position: relative
    margin-bottom: 0rem
    background: linear-gradient(215.91deg, #F4B965 12.73%, #F58C72 85.52%)
    min-height: 520px
    z-index: 2

    @include mobile
      margin-bottom: 0
      height: 720px

    @include from($widescreen)
      margin-bottom: 0rem

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 30%
      height: 105%
      top: 0px
      right: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 135%

    @include mobile
      .action-call
        display: block
        overflow: auto

    .image
      position: absolute
      z-index: 2
      right: 0px
      top: 120px
      width: 45%


      @include mobile
        position: relative
        margin: 3rem 15px 0 15px
        top: auto
        width: auto
        right: auto
        bottom: 60px

      img
        max-height: 260px
        max-width: 432px

        @include from($widescreen)
          max-height: 260px
          max-width: 432px

    .container
      position: relative
      z-index: 3

      @include mobile
        margin-left: 0
        width: auto
        display: block
        clear: both

      .testimonial-column
        padding-left: 15%

        @include mobile
          padding-left: 16px

      .testimonial-wrapper
        position: relative
        top: 98px
        overflow: hidden

        @include mobile
          width: 100%
          padding-top: 0
          top: 0

        .swiper-pagination
          padding-left: 0

          @include mobile
            display: none

        .swiper-pagination-bullet
          display: inline-block
          width: 8px
          height: 8px
          border-radius: 50%
          margin-right: 10px
          background: rgba(255,255,255,0.3)
          cursor: pointer

          &.swiper-pagination-bullet-active
            background: #FFFFFF

        .testimonial-padding
          padding: 0

          @include mobile
            padding: 0

        .testimonial-slide
          article.media
            @include mobile
              display: block

          figure.media-left
            margin-right: 24px
            margin-bottom: 24px

            @include mobile
              margin-right: 16px
              margin-bottom: 16px
              width: auto
              position: relative
              display: inline-block

            @media screen and (max-width: 360px)
              margin-right: 2px

            img
              width: 88px
              height: 88px

              @include mobile
                height: 64px
                width: 64px

          .media-content
            @include mobile
              width: auto
              overflow-x: initial
              top: -12px
              display: inline-block
              position: relative

            .content
              padding-right: 25%

              @include mobile
                padding: 0

              .name
                margin-top: 10px
                font-style: normal
                font-weight: 600
                font-size: 21px
                line-height: 24px
                letter-spacing: -0.01em
                color: #FFFFFF
                margin-bottom: 8px

                @include mobile
                  margin-top: 0
                  font-size: 18px


              .position
                font-style: normal
                font-weight: 500
                font-size: 14px
                line-height: 16px
                letter-spacing: -0.01em
                color: #FFFFFF

                @include mobile
                  font-size: 12px


          .body
            color: #FFFFFF
            font-style: normal
            font-weight: normal
            font-size: 16px
            line-height: 24px
            margin-bottom: 20px
            max-width: 500px

            @include mobile
              font-size: 14px
              line-height: 22px


    .initial-adjective
      position: absolute
      word-wrap: normal
      background: -webkit-linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    h1
      margin-top: 110px
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.02em
      font-size: 48px
      line-height: 56px
      color: #FFFFFF
      padding-right: 50px


      @include mobile
        font-style: normal
        font-weight: bold
        font-size: 30px
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-top: 56px

    h2
      @include homepage-standard
      margin-top: 24px
      font-size: 16px
      line-height: 26px
      padding-right: 26%
      color: #FFFFFF
      letter-spacing: 0.01em

      @include mobile
        font-size: 16px
        margin-top: 1rem
        line-height: 22px

    .action-call
      text-align: left
      margin-top: 50px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #FFFFFF !important

      a.button.btn-tertiary
        border: 1px solid white !important

        &:hover
          background: white !important
          color: #F58C72 !important



      @include mobile
        margin-top: 2rem
        margin-left: 0
        a.button.btn-tertiary
          padding: 33px 52px 31px 52px !important
          font-size: 17px
          line-height: 16px
          background: transparent !important
          border: 1px solid white !important
          position: absolute
          bottom: 20px
          width: 90% !important
          margin-left: 16px !important
          margin-right: 16px !important
          color: #FFFFFF !important
          height: 40px !important



      @include from($tablet)
        a.button.btn-tertiary
          padding: 33px 52px 31px 52px !important
          font-size: 17px
          line-height: 16px
          background: transparent
          border: 1px solid white
          color: #FFFFFF !important
          height: 64px !important

      span.i
        font-weight: 600

  .join-now-section
    position: relative
    height: 700px

    @include mobile
      margin-bottom: 0rem
      height: 600px
      background: #E4EFF6

    @include from($widescreen)
      max-height: 900px
      min-height: 700px
      height: auto

    .underlayer
      position: absolute
      background: #E4EFF6
      z-index: 1
      width: 30%
      height: 100%
      top: 0px
      left: 0px

      @include mobile
        display: none

      @include from($widescreen)
        height: 100%

    .image
      position: absolute
      z-index: 2
      top: 38px
      width: 45%
      float: left


      @include mobile
        position: relative
        margin: 20px 15px 0 15px
        top: auto
        width: auto
        right: auto
        left: 0
        clear: both

      @include from($widescreen)
        top: 20%

      img
        max-height: 534px
        max-width: 512px

        @include mobile
          max-height: 534px
          max-width: auto !important

        @include from($widescreen)
          max-height: 534px
          max-width: 512px


    .container
      position: relative
      z-index: 3
      top: 100px


      @include mobile
        clear: both
        width: auto
        margin-left: 0
        margin-right: 0
        float: none
        top: 56px
        display: block
        clear: both

      @include from($widescreen)
        top: 16%


    .initial-adjective
      position: relative
      margin-right: 5%
      margin-left: 30%
      top: -8px
      word-wrap: normal
      background: -webkit-linear-gradient(225.47deg, #0BB2BA 1%, #0B3954 113.27%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      font-style: normal
      font-weight: bold
      font-size: 24px
      line-height: 32px
      text-align: right
      letter-spacing: -0.01em

      @include mobile
        font-size: 16px
        line-height: 24px
        margin-right: 0
        margin-left: 29%

      @media screen and (max-width: 425px)
        margin-left: 38%

      @media screen and (max-width: 375px)
        margin-left: 28%

      @media screen and (max-width: 360px)
        margin-left: 25%

      @media screen and (max-width: 320px)
        margin-left: 15%

    h1
      margin-top: 46px
      @include homepage-heading
      font-weight: 700
      letter-spacing: -0.03em
      font-size: 48px
      line-height: 56px
      color: #0B3954


      @include mobile
        font-size: 30px
        text-shadow: none
        line-height: 38px
        letter-spacing: -0.02em
        padding-right: 10%
        margin-top: 56px

    h2
      @include homepage-standard
      font-style: normal
      font-weight: bold
      font-size: 40px
      line-height: 48px
      letter-spacing: -0.03em
      color: #0B3954
      margin: 0
      display: inline-block

      @include mobile
        font-size: 30px
        margin-top: 0
        line-height: 38px

    .action-call
      text-align: left
      margin-top: 24px
      display: inline-block
      font-weight: 500
      font-size: 16px
      line-height: 26px
      letter-spacing: 0.01em
      color: #0B3954
      margin-bottom: 32px
      position: relative
      top: 30%

      @include mobile
        margin-top: 2rem
        position: absolute
        bottom: 20px
        left: 0
        top: auto
        width: 100%
        a.button.btn-primary
          width: 92%
          margin: 16px
          margin-bottom: 8px

    .btn-primary
      margin-top: 8px !important
      width: 100% !important
      height: 64px !important
      font-size: 17px !important
      padding: 33px 52px 31px 52px !important


      @include mobile
        width: 100% !important
        position: relative
        padding: 15px 30px 15px 30px !important

    .schedule-btn
      font-style: normal
      font-weight: bold
      font-size: 17px
      line-height: 16px
      text-align: center
      letter-spacing: 0.03em
      text-transform: uppercase
      color: #55778A
      position: relative
      top: 0
      margin-left: 27px

      @include mobile
        top: 0
        display: block
        margin-left: 0
        margin: auto

      &:hover
        color: #0B3954

    .professional-logo
      margin-top: 20%

      @include mobile
        margin-top: 0

      img
        max-height: 40px

    .line
      margin: 8px 0
      i
        display: inline-block
        font-size: 24px
        position: relative
        top: 6px
      p
        display: inline-block
        font-style: normal
        font-weight: 500
        font-size: 16px
        line-height: 24px
        letter-spacing: -0.01em
        color: #0B3954
        margin-left: 12px

    .buttons
      margin-top: 8px


  footer
    z-index: 3
    margin-top: 0 !important

    @include mobile
      clear: both

  .modal
    z-index: 999
    .modal-content
      max-width: 1110px
      width: auto
      height: 100vh

      @include mobile
        max-width: 100%
        max-height: calc(100vh - 100px)
        overflow-y: hidden