.notice
  position: relative
  padding: 10px 10px 10px 50px
  font-size: 14px
  color: $black-light
  border: 1px solid $black
  border-radius: 10px
  margin-bottom: 1rem

  i
    position: absolute
    left: 15px
    top: 10px
    font-size: 25px

  &.is-success
    border-color: $success

    i
      color: $success

  &.is-danger
    border-color: $danger

    i
      color: $danger