body.calendars
  .fc
    padding: 0
    border: 0

    .fc-toolbar.fc-header-toolbar
      margin-left: 24px

    .fc-timegrid-axis-frame-liquid
      height: unset
      position: relative !important
      width: 75px

    .fc-daygrid-body
      height: 1.5rem

    .fc-scrollgrid
      border: 0 !important

      .fc-scrollgrid-section > td
        border-bottom: none

    .fc-view-harness-active
      max-height: calc(100vh - 182px)

    .fc-timegrid-divider
      padding: 0
      display: none

    .fc-view-harness
      background-color: #fff
      border-bottom: 1px solid #c8d6df

    .fc-scrollgrid-liquid
      border-top: 0

    .fc-day-today
      background-color: #fff
      border-right: 2px solid #E4EFF6 !important

    .fc-col-header-cell
      padding-top: 10px
      padding-bottom: 10px
      font-weight: 700
      text-align: center
      font-family: Manrope
      font-size: 18px
      color: #55778A

      .day-name
        display: block
        font-size: 14px

      &.fc-day
        font-size: 14px

      &.fc-day-today
        background-color: #F5FAFD
        font-weight: 700
        color: #0B3954

      &:not(.fc-day-today)
        background-color: #fff

      .fc-col-header-cell-cushion
        text-transform: uppercase


    .event-description,
    .event-title
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      display: block

    .fc-timegrid-now-indicator-line
      border-width: 1px

    td, th
      border-top: 1px solid #E4EFF6
      border-color: #E4EFF6

      &.fc-timegrid-slot-minor
        border-top: none

      &.fc-timegrid-slot-label
        border-top: 0

      .fc-daygrid-event
        margin: 0px !important
        border-radius: 0px !important
        padding: 1px !important


        .fc-event-main
          text-align: center
          width: 100%

          .event-title
            color: #55778A !important

      .fc-timegrid-event
        .fc-event-main
          overflow: hidden

          .event-title
            display: block
            font-weight: 800
            font-size: 12px

          .event-time
            display: block
            font-size: 12px
            font-weight: 400
            margin-bottom: 4px

      .fc-timegrid-event.personal-event
        background: repeating-linear-gradient(-45deg, #F5FAFD, #F5FAFD 9px, #E4EFF6 1px, #E4EFF6 11px) !important
        border-color: transparent !important

        .fc-event-main
          .event-title
            color: #55778A

          .event-time
            color: #55778A

      .fc-timegrid-event.other-event
        background-color: #C8D6DF

        .fc-event-main
          .event-title
            color: #0B3954

          .event-time
            color: #0B3954

          .event-description
            color: #0B3954


      .fc-daygrid-event
        display: flex
        flex-direction: row-reverse
        justify-content: flex-end
        padding: 3px
        color: #fff
        margin-top: 3px


        &.primary-event
          background-color: #55778A

        &.personal-event
          background: repeating-linear-gradient(-45deg, #F5FAFD, #F5FAFD 9px, #E4EFF6 1px, #E4EFF6 11px) !important
          color: #55778A

        &.other-event
          background-color: #C8D6DF
          color: #0B3954

        .event-title
          margin-right: 5px
          font-weight: 800

        .event-time
          margin-right: 5px
          font-weight: 00

        .event-description
          display: none

      .fc-daygrid-event.personal-event
        .fc-event-main
          .event-title
            color: #0B3954

          .event-time
            color: #0B3954

    .fc-toolbar-chunk
      display: flex
      align-items: baseline

    .fc-toolbar-title
      display: inline-block
      font-size: 16px
      font-weight: 400
      background-color: #fff
      font-family: Manrope
      padding: 8px 12px
      line-height: 16px
      border: none
      margin: 0px 2px
      min-width: 170px
      text-align: center

    .fc-button-group
      .fc-button-primary
        border-radius: 4px 0px 0px 4px
        border: 1px solid #C8D6DF
        background-color: #F5FAFD
        color: #55778A
        font-weight: 400

        &.fc-dayGridMonth-button
          border-radius: 0px 4px 4px 0px

          &:hover
            background-color: #E4EFF6

          &:active
            background-color: #C8D6DF

          &:focus
            box-shadow: 0 0 0 2px #8FD5FC !important

        &.fc-timeGridWeek-button
          border-radius: 0px

          &:hover
            background-color: #E4EFF6

          &:active
            background-color: #C8D6DF

          &:focus
            box-shadow: 0 0 0 2px #8FD5FC !important

        &.fc-timeGridDay-button
          &:hover
            background-color: #E4EFF6

          &:active
            background-color: #C8D6DF

          &:focus
            box-shadow: 0 0 0 2px #8FD5FC !important

        &.fc-button-active
          background-color: #fff
          color: #0B3954
          font-weight: 700


    .fc-button-primary
      background-color: #fff
      border: none
      margin-bottom: 2px
      color: #0B3954
      text-transform: capitalize
      padding: 8px 16px
      font-weight: 500
      font-size: 16px
      line-height: 16px


      &:active
        background-color: #E4EFF6

      &:focus
        box-shadow: none !important

      &.fc-today-button
        border-radius: 4px
        border: 1px solid #C8D6DF
        font-weight: 700
        color: #0B3954

        &:disabled
          color: #0B3954
          font-weight: 700
          opacity: 1

        &:active
          background-color: #E4EFF6

        &:focus
          box-shadow: 0 0 0 2px #8FD5FC !important

      &.fc-next-button
        border-radius: 4px
        margin-left: 1px
        padding: 6px

        &:hover
          background-color: #E4EFF6

        &:active
          background-color: #C8D6DF

        &:focus
          box-shadow: 0 0 0 2px #8FD5FC !important

      &.fc-prev-button
        border-radius: 4px
        margin-right: 1px
        padding: 6px

        &:hover
          background-color: #E4EFF6

        &:active
          background-color: #C8D6DF

        &:focus
          box-shadow: 0 0 0 2px #8FD5FC !important

      &.fc-createAppointment-button
        border: none
        background: #0D9DA4
        color: #fff
        text-transform: none
        padding: 8px 16px
        font-size: 16px
        line-height: 16px
        font-weight: 700


        &:focus
          outline: none
          box-shadow: 0 0 0 2px #3B82F6 !important

        &:active
          background-color: #077D83

      .fc-icon
        color: #55778A

  .fc-timegrid-axis-frame
    justify-content: flex-end

    .fc-timegrid-axis-cushion
      text-transform: capitalize
      max-width: 75px
      font-size: 12px
      width: 100%
      text-align: center
      line-height: 24px

  .fc-direction-ltr .fc-timegrid-slot-label-frame
    text-align: left

  .fc .fc-timegrid-slot-label-frame
    position: relative

  .fc .fc-timegrid-slot-label-cushion
    padding-left: 10px
    font-size: 12px
    position: absolute
    top: -12.5px
    text-align: center
    width: 75px

  td[data-time="00:00:00"]
    display: none
  
  .fc
    .fc-timegrid-slot
      height: 0.75em !important
      min-height: 0.75em !important
      line-height: 0.75em !important

    .fc-timegrid
      line-height: 0.75em !important

    .fc-timegrid-body
      height: auto !important
      min-height: 100% !important

    .fc-timegrid-slot-lane
      height: 0.75em !important
      min-height: 0.75em !important


  @include until-widescreen
    .fc .fc-today-button
      display: none

    .fc-toolbar-chunk:nth-of-type(1)
      flex-direction: column-reverse
      align-items: normal

    .fc-toolbar-chunk:nth-of-type(3)
      align-self: end
      margin-bottom: 10px

    .fc-direction-ltr .fc-toolbar > * > :not(:first-child)
      margin-left: 0


  @include touch
    .fc
      min-height: calc(100vh - 80px)
      padding: 5px

      .fc-view-harness-active
        max-height: none

      .fc-timegrid-slot-label-cushion
        width: 50px

      .fc-timegrid-axis-frame
        justify-content: flex-start

        .fc-timegrid-axis-cushion
          width: 50px
          text-align: left

      .fc-col-header
        display: none


    .fc-dayGridMonth-view,
    .fc-timeGridWeek-view
      .event-time
        display: none !important

      .event-description
        display: none

    .fc-today-button
      display: none

    .fc-toolbar.fc-header-toolbar
      flex-direction: column-reverse
      margin-top: -30px
      margin-bottom: 10px

      .fc-toolbar-chunk:nth-of-type(1)
        .fc-button-group
          display: none

      .fc-toolbar-chunk:nth-of-type(3)
        align-self: center
        margin-bottom: 20px

    .fc-createAppointment-button
      position: fixed
      bottom: 80px
      right: 16px
      z-index: 10





