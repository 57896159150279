.pro_searches

  @media screen and (max-width: 1023px)
    .columns .column
      padding: 0.75rem !important
  @media screen and (max-width: 768px)
    .columns .column
      padding: 0px !important

      .heading
        margin-top: 20px

  .navbar
    @include mobile
      border-bottom: 1px solid #C8D6DF
    .navbar-brand
      margin-left: -10px
      @include mobile
        margin-left: 20px
      .navbar-item
        @include mobile
          padding: 0.75rem 0px 0.75rem 0
          font-size: 22px

    .navbar-container
      .navbar-end
        margin-right: -15px
      .navbar-list-practice
        @include mobile
          margin-top: 10px

    .navbar-menu
      &.navbar-fill
        @media (max-width: 1299px)
          min-width: 200px
          max-width: 200px
        @media (max-width: 1215px)
          display: none

      .dropdown-content
        padding: 46px 0
      a.button.login
        @include mobile
          margin-top: 60px !important
  .places-container
    .icon-search
      top: 44px
      margin-left: 10px
      position: relative
      z-index: 99
  .ais-GeoSearch-map
    min-height: 90vh

  .not_active ~ div[data-react-component="ProSearch"]

    .places-container
      top: 30px

      .virtual-toggle,
      .icon-pin,
      .icon-search
        top: 66px

    .results-container
      margin-top: 3em

  .searchbar
    .searchbar-container
      z-index: 9999
      position: fixed
      top: 12px
      @media (min-width: 1425px)
        left: 27%
      @media (min-width: 1300px) and (max-width: 1424px)
        left: 23%
      @media (min-width: 1215px) and (max-width: 1299px)
        left: 20%
      @media (min-width: 1024px) and (max-width: 1214px)
        left: 12%
      @media (min-width: 769px) and (max-width: 1023px)
        left: 20%

      .icon-search
        position: fixed
        font-size: 1.6em
        margin-left: 10px
        margin-top: 15px

      .icon-search:before
        color: #889FAD

      .icon-close
        border-radius: 50%
        padding: 2px
        font-size: 15px
        position: absolute
        height: 24px
        width: 24px
        background: #F3F8FB
        text-align: center
        line-height: 1.5
        top: 15px
        left: 252px

        @media (min-width: 1024px) and (max-width: 1099px)
          left: 185px

      .icon-close:hover
        cursor: pointer

      .react-autosuggest__input
        box-shadow: 0px 8px 16px -4px rgba(107, 134, 153, 0.1)
        width: 289px
        height: 48px
        border: 1px solid #E4EFF6
        border-radius: 4px 0 0 4px
        margin-top: 4px
        padding: 19px 20px 17px 44px
        color: #0B3954
        font-size: 16px
        @media (min-width: 1023px) and (max-width: 1099px)
          width: 220px

      .react-autosuggest__input::placeholder
        font-size: 16px
        padding-left: 5px

      .react-autosuggest__container:focus-within + .icon-search:before
        color: #0D9DA4 !important

      .react-autosuggest__input:focus
        outline: none !important
        border: 1px solid #0D9DA4 !important
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px -4px rgba(107, 134, 153, 0.1)
        border-radius: 4px 0px 0px 4px

      .react-autosuggest__suggestions-container--open
        position: absolute
        background: #fff
        padding: 24px 24px
        width: 652px
        height: 452px
        box-sizing: border-box
        border: 1px solid #E4EFF6 !important
        box-shadow: 0px 8px 16px -4px rgba(107, 134, 153, 0.1)
        overflow-y: auto
        margin-top: 4px
        color: #0B3954
        z-index: 999
        @media (min-width: 1024px) and (max-width: 1099px)
          width: 583px

        .react-autosuggest__suggestion--highlighted .ais-Highlight-highlighted,
        .react-autosuggest__suggestion .ais-Highlight-highlighted
          background: none !important
          font-weight: 800
          color: #0B3954

        .react-autosuggest__section-title
          font-weight: 500
          font-size: 18px
          margin-bottom: 16px

        .react-autosuggest__suggestion
          margin-bottom: 24px

          &:hover
            cursor: pointer

          .pro-row-suggestion
            display: flex
            height: 56px

            .pro-row-suggestion-img
              width: 56px
              margin-right: 10px

              img
                border-radius: 50%

            .pro-row-suggestion-name
              font-family: 'Manrope'
              font-style: normal
              font-weight: 600
              font-size: 16px

            .pro-row-suggestion-info
              vertical-align: middle

              .icon-star-filled
                margin-right: 2px

              .icon-star-filled:before, span
                color: #F4B965

              .pro-row-suggestion-reviews
                color: #55778A
      .location-search-container
        width: 363px

        .location-search-input
          box-shadow: 0px 8px 16px -4px rgba(107, 134, 153, 0.1)
          width: 363px
          height: 48px
          border: 1px solid #E4EFF6
          border-radius: 0 4px 4px 0
          margin-top: 4px
          padding: 19px 20px 17px 44px
          color: #0B3954
          font-family: inherit
          font-style: normal
          font-weight: 400
          font-size: 16px
          line-height: 24px
          @media (min-width: 1024px) and (max-width: 1099px)
            width: 330px

        .location-search-input:focus
          outline: none !important
          border: 1px solid #0D9DA4 !important
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px -4px rgba(107, 134, 153, 0.1)
          border-radius: 0 4px 4px 0

        .location-search-input::placeholder
          font-size: 16px

        .icon-map-pin2
          position: fixed
          font-size: 1.6em
          top: 26px
          margin-left: -350px
          @media (min-width: 1024px) and (max-width: 1099px)
            margin-left: -310px

        .button-tertiary
          position: fixed
          background: #0D9DA4
          color: white
          width: 89px
          height: 40px
          border-radius: 4px
          font-family: inherit
          font-style: normal
          font-weight: 700
          font-size: 16px
          line-height: 16px
          margin-left: -95px
          top: 18.75px
          padding: 1px

          &:hover
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0D9DA4

        .location-search-box
          display: block
          width: 363px
          height: 112px
          background: white
          position: absolute
          margin-top: 4px
          flex-wrap: wrap

          .location-search-row
            width: 100%
            height: 56px
            padding-top: 16px
            padding-bottom: 15px
            padding-left: 27px
            font-weight: 400
            font-size: 16px
            align-items: center

            .location-search-icon
              color: #0D9DA4
              margin-right: 16px
              display: inline-block

            .location-search-text
              display: inline-block

          .location-search-row:hover
            background: #F3F8FB
            cursor: pointer
      .location-search-container:focus-within

        .icon-map-pin2:before
          color: #0D9DA4

    @include mobile
      .mobile-search-toggle
        position: absolute
        top: 73px
        display: flex
        justify-content: center
        align-items: center
        width: 100%

        div
          width: 90%

        .icon-search
          position: absolute
          font-size: 1.6em
          margin-left: 10px
          margin-top: 13px

        .icon-search:before
          color: #889FAD

      .searchbar-container
        width: 89%
        background: #FFF
        position: relative
        top: 0

        .icon-search
          position: absolute
          font-size: 1.6em
          margin-left: 15px
          margin-top: 0
          top: 19px

        .icon-search:before
          color: #889FAD

        .icon-close
          border-radius: 50%
          padding: 2px
          font-size: 15px
          position: absolute
          height: 24px
          width: 24px
          background: #F3F8FB
          text-align: center
          line-height: 1.5
          top: 19px
          left: 89%

        .icon-close:hover
          cursor: pointer

        .react-autosuggest__input
          width: 100%
          box-shadow: none
          height: 54px
          border: 1px solid #C8D6DF
          border-radius: 4px
          margin-top: 4px
          padding: 19px 20px 17px 55px
          color: #0B3954
          font-size: 16px

        .react-autosuggest__input::placeholder
          font-size: 16px
          padding-left: 5px

        .react-autosuggest__container:focus-within + .icon-search:before
          color: #0D9DA4 !important

        .react-autosuggest__input:focus
          outline: none !important
          border: 1px solid #0D9DA4 !important
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px -4px rgba(107, 134, 153, 0.1)
          border-radius: 4px 0px 0px 4px

        .react-autosuggest__suggestions-container--open
          position: absolute
          background: #fff
          padding: 12px 24px
          height: 452px
          box-sizing: border-box
          border-top: 6px solid #f3f8fb !important
          overflow-y: auto
          margin-top: 4px
          color: #0B3954
          width: max-content
          top: 127px
          left: -6%

          .react-autosuggest__suggestion--highlighted .ais-Highlight-highlighted,
          .react-autosuggest__suggestion .ais-Highlight-highlighted
            background: none !important
            font-weight: 800
            color: #0B3954

          .react-autosuggest__section-title
            font-weight: 500
            font-size: 18px
            margin-bottom: 16px

          .react-autosuggest__suggestion
            margin-bottom: 24px

            .pro-row-suggestion
              display: flex
              height: 56px

              .pro-row-suggestion-img
                width: 56px
                margin-right: 10px

                img
                  border-radius: 50%
            .pro-row-suggestion-name
              font-family: 'Manrope'
              font-style: normal
              font-weight: 600
              font-size: 16px

            .pro-row-suggestion-info
              vertical-align: middle

              .icon-star-filled
                margin-right: 2px

              .icon-star-filled:before, span
                color: #F4B965

              .pro-row-suggestion-reviews
                color: #55778A

        .location-search-container
          display: flex
          width: 100%
          height: 64px

          .location-search-input
            background: #FFF
            height: 54px
            width: 100%
            border: 1px solid #C8D6DF
            border-radius: 4px
            box-shadow: none
            padding: 19px 20px 17px 55px

          .button-tertiary
            position: absolute
            margin-left: auto
            margin-right: auto
            top: 296px
            width: 100%
            height: 56px

          .icon-map-pin2
            position: absolute
            margin-left: 20px
            top: 83px

          .location-search-box
            margin-top: 70px
            margin-left: -20px
            width: 100vw

  #address-input
    position: relative
    background: white
    border: 1px solid #C8D6DF
    color: #0B3954
    font-size: 15px
    padding: 19px 20px 17px 44px
    margin-top: 5px
    height: 48px !important
    border-radius: 6px
    min-width: 275px
    width: 100%


  .app-download-section
    display: none
  .top-pills
    margin-top: 12px !important
    @include mobile
      position: relative
      margin-top: 80px !important
      height: 45px

  .results-column, .map-column
    margin-top: 80px

    @include mobile
      margin-top: 16px !important


  .map-results
    padding-top: 10px

  .ais-Pagination
    margin-top: 30px
    margin-bottom: 30px
    display: flex
    align-content: center
    justify-content: center

  .ais-Pagination-list
    display: flex

  .ais-Pagination-link
    padding: 5px 10px
    background-color: #fff
    margin-right: 8px
    font-size: 12px

  .ais-Pagination-link:hover,
  .ais-Pagination-link--selected
    background: #0D9DA4
    border-radius: 4px
    color: white

  .ais-Pagination-item--disabled
    display: none

  .modal[data-name='video']
    .modal-content
      .box
        max-height: 325px
        padding: 5px
        iframe
          width: 100%
        video
          height: 315px

  .video-launcher
    display: block
    width: 100px
    height: 36px
    font-family: Manrope
    font-style: normal
    font-weight: 500
    font-size: 14px
    letter-spacing: -0.02em
    color: white
    text-align: center
    background: #0B3954
    border-radius: 0px
    --animate-duration: 1.4s
    padding: 6px
    position: absolute
    bottom: 7px
    right: 0px

    &:hover
      cursor: pointer

    i
      position: relative
      margin-left: 3px
      margin-top: 4px
      margin-right: 5px

  .gm-bundled-control
    position: absolute
    right: 60px !important
    top: 5px !important
    left: unset !important

  .loading
    position: fixed
    top: 30vh
    left: 49vw

  @include desktop-only
    .navbar-item.pro-page
      display: none

  @include tablet-only
    .navbar-item.pro-page
      display: none

  @include mobile
    .navbar-menu.is-active
      margin-top: 0px
      z-index: 9999

    .loading
      position: fixed
      top: 30vh
      left: 25vw

    .video-launcher
      display: none

    .map-results-columns
      padding-top: 0px

    .results-container
      margin-top: 55px

      .results-column
        border-top: none

        .practice-item

          .practice-details
            padding-left: 0px
            display: inline-block

          .service-row
            .mobile-spacer
              padding-top: 0.75rem
              padding-bottom: 0.75rem

              .column
                padding-top: 0.5rem
                padding-bottom: 0.5rem

    .ais-GeoSearch-map
      height: 100vh

    .icon-close
      top: 94px
      right: 10px
      font-size: 1.3em
      z-index: 1004

    #address-input
      padding-left: 16px
      font-size: 15px
      height: 48px !important
      min-width: auto
      width: calc(100vw - 48px)
      border-radius: 6px

    #address-input
      padding-left: 47px

    .map-control
      position: fixed
      top: 10px
      right: 10px
      z-index: 999
      padding: 10px

    &.button
      background-color: #fff

  .gm-bundled-control
    position: absolute
    right: 60px !important
    bottom: 150px !important
    left: unset !important
    top: unset !important
