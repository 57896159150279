.flashes
  width: 100%
  display: block
  text-align: center
  color: black
  max-height: 100px
  @include transition(max-height)
  overflow: hidden
  z-index: 9999
  position: fixed
  top: 0

  &[data-state='displayed']
    max-height: 0px

  .flash
    padding: 20px 10px
    background: white
    color: black
    font-weight: 600

    &.info
      background: $info
      color: white

    &.success
      background: $success
      color: white

    &.error
      background: $danger
      color: white

    &.warning
      background: $warning
      color: white