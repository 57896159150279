body.bookings
  background: white

  .container
    @media (min-width: 1408px)
      max-width: 1152px !important
    @media (min-width: 1520px)
      max-width: 1344px !important

  .modal-close-icon
    @include mobile
      top: 100px
      z-index: 9999

  .app-download-section
    display: none

  .booking-info
    display: flex
    margin-top: 30px
    align-items: center
    padding: 30px 25px 0 25px

    .practitioner-image
      display: flex
      width: 64px
      min-width: 64px
      height: 64px

      img
        height: 100%
        width: 100%
        border-radius: 50%

    .practitioner-info
      display: flex
      flex-flow: column
      padding-left: 24px

      .name
        font-weight: 700
        font-size: 16px
        line-height: 20px
        letter-spacing: -0.02em
        color: #0B3954

      .time
        font-weight: normal
        font-size: 14px
        line-height: 20px
        letter-spacing: -0.01em
        color: #0B3954

      .location
        font-weight: normal
        font-size: 14px
        line-height: 20px
        letter-spacing: -0.01em
        color: #55778A
