.practices-listing .practice-item

  margin: 12px 0
  background-color: #FFF !important

  &:hover
    background-color: #FFF !important

  .with-min-height
    min-height: 220px

    .listing-info
      padding-top: 16px
      border-bottom: 1px solid #E4EFF6

      .action-next-availability
        margin-left: 154px

      @include mobile
        margin-left: 0px !important
        position: relative
        top: 10px !important
        padding-right: 16px !important
        padding-left: 16px !important

        .action-next-availability
          margin-left: 0
          justify-content: center

          .next-availability
            padding-top: 10px
            height: 32px !important
            .btn-primary
              display: block
              border-radius: 0 !important
              text-transform: none
              height: 32px !important
              font-weight: 500 !important
              font-size: 13px !important
              width: 100% !important
              padding: 5px !important

  .column.is-narrow.photo
    @include mobile
      padding-top: 0 !important

.listing-divide
  margin-left: 2.2rem
  @include mobile
    margin-left: 10px !important

    &.sticky-top
      margin-top: 29px !important

.calendar-dates
  margin-right: 8px
  table.booking-calendar
    width: 97%
    align-content: center
    position: relative
    z-index: 1

    thead
      tr
        margin: 0 10px
        th
          padding-bottom: 0
          width: 25%
          text-align: center

          font-family: DM Sans
          font-style: normal
          font-weight: normal
          font-size: 14px
          letter-spacing: -0.01em
          color: #0B3954

          span.day
            display: block
            font-weight: normal
            font-size: 14px
            line-height: 16px

          span.date
            display: block
            font-weight: 500
            font-size: 14px
            line-height: 16px
  .table-container
    position: relative
    overflow: visible
    height: 100%
    width: 100%

    a.paginate
      position: absolute
      top: 10px
      font-size: 20px
      z-index: 10
      width: 32px
      height: 32px
      border-radius: 50px

      i
        position: relative
        left: -6px
        top: 2px

      &:hover
        background: #E4EFF6

      &.previous
        left: -10px
        top: 2px

      &.next
        right: 0px
        top: 2px

    .overflow-container
      position: absolute
      height: 80%
      width: 100%
      overflow-y: scroll

      &::-webkit-scrollbar
        width: 2px

      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)

      &::-webkit-scrollbar-thumb
        background-color: darkgrey
        outline: 1px solid #C8D6DF

    .submit-tooltip
      position: absolute
      color: white
      text-align: center
      padding: 2px
      width: 200px
      height: 30px
      top: 45%
      right: 105%
      background-color: $primary

      &::after
        content: ''
        position: absolute
        top: 50%
        left: 100%
        margin-top: -5px
        border-width: 5px
        border-style: solid
        border-color: transparent transparent transparent $primary

.practitioners-found
  margin-left: 2.2rem
  padding-top: 0
  span
    font-family: Manrope
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 32px
    display: flex
    align-items: flex-end
    letter-spacing: -0.01em
    color: #0B3954

  @include mobile
    margin: 2px 10px

.submenu-options
  position: relative
  span
    position: relative
    top: 18px
    margin-right: 24px
    font-size: 16px
    line-height: 24px
    color: #55778A
    margin-bottom: 16px

    &.active
      font-weight: 500
      border-bottom: 1px solid #0B3954
      padding-bottom: 16px
      color: #0B3954

      @include mobile
        padding-bottom: 20px

    &:hover
      cursor: pointer
      color: #0B3954

  @include mobile
    margin-left: 10px !important

  &.quiz
    @include mobile
      margin-bottom: 4px
  &.localities
    @include mobile
      padding-bottom: 7px !important

.practice-item
  position: relative
  margin: 10px 0 10px 0

  .virtual-badge-wrapper
    text-align: center
    margin-top: 10px

  .virtual-badge
    margin: 0 auto
    color: $primary
    display: inline-block
    border-radius: 20px
    background: rgba($primary, 0.13)
    padding: 6px 16px 3px 50px
    font-size: 14px
    position: relative

    @include mobile
      font-size: 12px
      padding: 6px 16px 3px

    &:before
      @include icon
      content: "\e946"
      font-size: 22px
      position: absolute
      left: 16px
      top: 3px

      @include mobile
        display: none

  .photo img
    margin-bottom: 18px
    border-radius: 0px
    max-width: 128px
    max-height: 128px

    @include mobile
      border-radius: 0px
      max-width: 72px
      max-height: 72px

  .rating
    text-align: left
    margin-top: 0px
    padding: 12px 0 0 0

    @include mobile
      margin-top: 0px
      font-size: 14px

    span
      display: inline-block
      padding: 0 2px

    span.stars
      background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      background-size: 100%
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      letter-spacing: -0.1em

    span.average
      color: #0B3954
      font-weight: 500
      position: relative
      top: -1px

    span.count
      font-size: inherit
      font-weight: normal
      color: #55778A
      position: relative
      top: -1px

  @include mobile
    .practice-details
      padding-left: 20px

  h2
    margin: 0
    a
      color: #0B3954
      @include transition(color)
      font-weight: bold
      font-size: 20px
      line-height: 30px
      padding-bottom: 4px

    @include mobile
      font-size: 18px
      line-height: 23px

  .description
    font-size: 14px
    font-weight: normal
    line-height: 16px
    color: #0B3954

    @include mobile
      font-size: 12px

  .location
    margin-top: 8px
    position: relative
    font-size: 14px
    font-weight: normal
    color: #55778A
    line-height: 16px

    @include mobile
      font-size: 13px
      padding-left: 0

    span
      display: inline-block
      padding: 0 5px

  .virtual
    margin-top: 0.5rem
    padding-left: 25px
    position: relative

    @include mobile
      font-size: 13px
      padding-left: 0

    &:before
      @include icon
      content: "\e946"
      position: absolute
      display: inline-block
      font-size: 20px
      left: 0px
      top: 0px

      @include mobile
        display: none

    span
      display: inline-block
      padding: 0 5px

  .recent-review
    padding-right: 0px
    position: relative
    font-size: 14px
    line-height: 18px
    color: #0B3954

    a
      color: #0D9DA4

    @include mobile
      font-size: 13px
      padding: 0
      background: none
      margin-top: 0.75rem

  .actions
    margin: 6px 0
    display: inline-block

    @include mobile
      margin-top: 0.75rem
      margin-bottom: 10px

    a
      display: inline-block
      color: #0D9DA4
      position: relative
      padding: 10px 0px 10px 30px
      @include transition(color)
      font-weight: normal
      left: -12px

      i
        display: inline-block
        position: absolute
        left: 0px
        top: 4px
        height: 32px
        width: 32px
        font-size: 14px
        color: #0D9DA4
        text-align: center
        line-height: 30px
        border-radius: 50px
        @include transition(background)

      @include mobile
        font-size: 12px
        font-weight: bold

      span
        font-size: 12px
        line-height: 16px
        letter-spacing: 0.03em
        text-transform: uppercase
        position: relative
        top: -4px

        @include mobile
          top: 0

  .booking-time-selection
    position: relative
    height: 85%
    overflow: hidden
    .table-container
      position: absolute
      overflow: visible
      height: 100%
      width: 100%

      a.paginate
        position: absolute
        top: 10px
        font-size: 20px
        z-index: 10

        &.previous
          left: 0px

        &.next
          right: 0px

      .overflow-container
        position: absolute
        height: 86%
        width: 100%
        top: 16px
        overflow-y: hidden

        &::-webkit-scrollbar
          width: 2px

        &::-webkit-scrollbar-track
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)

        &::-webkit-scrollbar-thumb
          background-color: darkgrey
          outline: 1px solid #C8D6DF

      .submit-tooltip
        position: absolute
        color: white
        text-align: center
        padding: 2px
        width: 200px
        height: 30px
        top: 45%
        right: 105%
        background-color: $primary

        &::after
          content: ''
          position: absolute
          top: 50%
          left: 100%
          margin-top: -5px
          border-width: 5px
          border-style: solid
          border-color: transparent transparent transparent $primary

  .services-section
    position: relative
    height: 100%
    .pro-search-services
      position: relative
      max-height: 80%
      overflow-y: scroll
      margin: 10px 0

      .services-offered
        padding: 0px 8px

        li
          list-style-type: circle
          font-weight: 500
          font-size: 18px

    .book-now
      position: absolute
      bottom: 0px
      width: 100%
      margin: 10px 0

    .btn-secondary
      &:hover
        color: #0D9DA4 !important

  table.booking-calendar
    width: 97%
    align-content: center
    position: relative
    z-index: 1

    thead
      tr
        th
          padding-bottom: 0.5rem
          width: 25%
          text-align: center

          font-family: DM Sans
          font-style: normal
          font-weight: normal
          font-size: 14px
          letter-spacing: -0.01em
          color: #0B3954

          span.day
            display: block
            font-weight: normal

          span.date
            display: block
            font-weight: 500

    tbody
      tr
        td
          width: 25%
          text-align: center
          padding: 0 4px

          a
            display: block
            padding: 7px 5px 5px 5px
            box-shadow: none
            font-family: DM Sans
            font-style: normal
            font-weight: 500
            font-size: 12px
            letter-spacing: -0.02em
            color: #FFFFFF
            background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
            margin-bottom: 10px
            text-transform: lowercase

            &:hover, &.selected
              background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
              color: white

          span.empty-slots
            display: block
            padding: 7px 5px 5px 5px
            box-shadow: none
            color: #55778A
            background: transparent
            margin-bottom: 4px

  .see-all-times
    position: absolute
    margin: auto
    right: 40px
    a
      display: block
      padding: 7px 5px 5px 5px
      box-shadow: none
      font-family: DM Sans
      font-style: normal
      font-weight: 500
      font-size: 12px
      letter-spacing: -0.02em
      color: #0D9DA4
      margin-bottom: 10px
      text-align: right
      text-decoration-line: underline
      text-transform: uppercase

      &:hover, &.selected
        color: #0D9DA4

.practice-mini
  position: relative
  margin-top: 1.5rem

  .image
    padding: 4px
    position: absolute
    left: 0px
    top: 0px
    display: inline-block

    img
      width: 96px
      height: 96px

      @include mobile
        width: 50px
        height: 50px

  .body
    padding-left: 120px
    position: relative

    @include mobile
      padding-left: 75px

    .name a
      font-size: 18px
      color: $black
      font-weight: 500
      display: inline-block

      @include mobile
        font-size: 16px

      &:hover
        color: $primary

    .rating
      text-align: center
      position: absolute
      top: 0px
      right: 0px
      padding-left: 25px
      display: inline-block

      @include mobile
        margin-top: 0px
        font-size: 14px

      span
        display: inline-block
        padding: 0

      span.stars
        background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        background-size: 100%
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        letter-spacing: -0.1em

      span.average
        background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        background-size: 100%
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        letter-spacing: -0.1em

      span.count
        font-size: inherit
        font-weight: inherit

    @include mobile
      .practice-details
        padding-left: 20px

    .title
      margin-top: 10px
      @include subheading
      font-size: 12px

      @include mobile
        margin-bottom: 1rem

    .location
      color: #55778A

      @include mobile
        position: relative
        top: auto
        right: auto
        font-size: 14px
        padding-left: 0px

      &:before
        @include icon
        content: "\e93b"
        display: inline-block
        font-size: 20px

        @include mobile
          font-size: 16px

      span
        display: inline-block
        padding: 0 5px

  .description
    margin-top: 1.5rem
    position: relative
    font-size: 16px
    font-weight: 400

    @include mobile
      margin-left: 0
      font-size: 14px
      line-height: 25px

    span.triangle
      background: $grey-background
      position: absolute
      top: -8px
      left: 24px
      width: 24px
      height: 24px
      display: inline-block
      transform: rotate(47deg)
      z-index: -1

article.media.practice-sidebar
  .media-content
    .content
      display: flex
      position: relative
      overflow-wrap: break-word
      margin-bottom: 5px
      margin-top: 4px

      .name a
        @include h5style
        font-size: 16px
        font-weight: 500
        line-height: 18px
        display: inline-block
        margin-bottom: 0px

        &:hover
          color: $primary

      .title
        font-size: 14px
        color: #55778A
        font-weight: 500
        margin-bottom: 0
        display: block
        padding-right: 10px

      .rating
        font-size: 14px
        font-weight: 400
        margin-left: -4px
        display: flex
        align-items: center
        justify-content: center
        margin-top: 0px

        span.stars
          color: $primary
          letter-spacing: -0.1em

        span.average
          display: inline-block
          margin-left: 4px
          background-image: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
          background-size: 100%
          -webkit-background-clip: text
          -webkit-text-fill-color: transparent
          font-size: 14px
          margin-right: 4px
          line-height: 1

        span.count
          display: inline-block
          margin-left: 2px
          color: $black
          font-weight: 500
          margin-bottom: 2px !important
          margin-right: 2px
          line-height: 1

        span.total
          display: inline-block
          margin-left: 2px
          color: $black-light
          font-weight: 400

          line-height: 1

      .location
        position: relative
        padding-left: 24px
        font-size: 14px
        color: #55778A
        display: block

        &:before
          @include icon
          content: "\e93b"
          position: absolute
          display: inline-block
          font-size: 17px
          left: 0px
          top: 2px

      .virtual
        position: relative
        padding-left: 24px
        font-size: 14px
        color: #55778A
        display: block

        &:before
          @include icon
          content: "\e946"
          position: absolute
          display: inline-block
          font-size: 17px
          left: 0px
          top: 2px

.filter-modal
  .modal
    z-index: 99999

    hr
      margin: 1.5rem 0

    .box
      border-radius: 0

  h3
    font-size: 24px

  .label-bold
    color: #0B3954
    font-family: inherit
    font-weight: 500
    line-height: 24px
    font-size: 18px
    display: block
    margin-bottom: 8px

  .insurance-accepted
    max-height: 220px
    overflow: hidden

  .is-expandable
    background: transparent !important

  .btn-primary
    position: relative !important
    top: 0 !important

  .clear-all
    background: transparent !important
    text-decoration-line: underline
    text-transform: uppercase
    font-size: 14px
    line-heeight: 16px
    font-weight: bold
    border: 0px
    position: relative
    top: 10px
    color: #0B3954

  .check_boxes_inline_halves2 span.checkbox
    width: 50%
    margin-bottom: 0
    display: inline-block

.sticky-calendar
  background: #F3F8FB
  padding-top: 30px
  padding-bottom: 10px

  &.sticky
    position: sticky
    top: 78px
    z-index: 99

  @include mobile
    min-height: 70px

    &.sticky
      top: 50px

  &.localities
    .practitioners-found
      span
        display: inline-block
        position: relative
        top: 0px
        margin-right: 14px
      .btn-tertiary
        width: auto !important
        display: inline-block
        height: 86% !important
        padding: 12px 15px 15px 15px !important

      @include mobile
        span
          font-size: 21px
          display: block
          margin-bottom: 16px

        .btn-tertiary
          padding: 15px 15px 15px 15px !important
          width: 49% !important

  &.quiz
    width: 75%

    @include mobile
      width: 100%

.quiz-practices
  padding: 12px

  .btn-primary
    width: 100%

  .btn-tertiary
    height: 53px !important

  hr
    @include mobile
      position: relative
      top: -42px

  .outer-wrapper
    @include mobile
      padding-top: 0px !important

  .photo
    padding-top: 12px
    display: inline-block
    position: relative
    width: 218px

    @include mobile
      max-width: 72px
  .practice-details
    width: 100%
    display: inline-block
    color: #55778A
    padding: 12px !important

    @include mobile
      padding: 0 0 12px 0 !important
      position: relative
      width: 80%
      top: -84px
      left: 84px

      .title
        display: block
        margin: 8px 8px 8px 0
        margin-bottom: 8px !important

    .name
      font-weight: 600
      font-size: 18px
      line-height: 24px
      letter-spacing: -0.01em
      color: #0B3954

      @include mobile
        font-size: 16px
        line-heeight: 20px

    .title
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: -0.01em
      color: #0D9DA4
      margin-top: 8px
      margin-bottom: 24px

      @include mobile
        font-size: 12px
        line-height: 16px

    .location
      font-weight: normal
      font-size: 14px
      line-height: 16px
      letter-spacing: -0.01em
      color: #55778A
      margin-top: 4px
      margin-bottom: 24px

      @include mobile
        font-size: 12px
        line-height: 18px

  .action-buttons
    @include mobile
      position: relative
      top: -84px

  .result-data
    font-weight: 500
    font-size: 14px
    line-height: 16px
    color: #0B3954

    @include mobile
      position: relative
      top: -84px

    i
      &::before
        font-weight: 600

    .well-reviewed
      display: block
      padding: 6px 0

    .with-credentials
      display: block
      padding: 6px 0

    .distance
      display: block
      padding: 6px 0

    .with-endorsements
      display: block
      padding: 6px 0
