body.sessions, body.migrations, body.registrations, body.settings, body.password_resets
  .backdrop
    background-size: contain
    background-repeat: no-repeat
    background-position: bottom center

  section.box
    border-radius: 0px
    padding: 30px 25px 40px 25px

    @include mobile
      margin: 4rem 0.75rem 6rem 0.75rem
      padding: 30px 15px 40px 15px

    > i
      width: 86px
      height: 86px
      margin: -73px auto 0.75rem auto
      text-align: center
      font-size: 39px
      line-height: 86px
      display: block
      @include shadow
      border-radius: 50%
      background: white

    h1
      font-size: 30px
      text-align: center
      margin-bottom: 8px

    p.instruction
      text-align: center
      font-size: 14px
      margin-bottom: 2rem

    p.small
      font-size: 14px

    p.center
      text-align: center

    p.alternate-option
      display: none
      text-align: center
      font-size: 14px
      margin-top: 1rem

      @include mobile
        display: block

  footer
    margin-top: 0

body.sessions, body.migrations, body.registrations, body.password_resets

  nav.navbar
    background: $grey-background

body.sessions
  .password-wrapper
    position: relative
    margin-bottom: 2rem

    a
      position: absolute
      display: inline-block
      top: 0px
      right: 0px
      font-size: 12px

    .toggle-view-password
      position: absolute
      top: 50px
      right: 20px
      cursor: pointer


body.registrations
  .password-wrapper
    position: relative
    margin-bottom: 2rem

    a
      position: absolute
      display: inline-block
      top: 0px
      right: 0px
      font-size: 12px

    .toggle-view-password-registration
      position: absolute
      top: 50px
      right: 20px
      cursor: pointer
    
