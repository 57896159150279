@keyframes quizbar
  0%
    width: 10%
  20%
    width: 25%
  30%
    width: 25%
  50%
    width: 55%
  65%
    width: 55%
  100%
    width: 95%

body.quizzes

  .exit-link
    position: absolute
    top: 40px
    right: 45px
    z-index: 99

    @include mobile
      right: 20px
      top: 20px

    .quiz-close-icon
      border: 1px solid #C8D6DF  
      height: 48px
      width: 48px
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center

      @include mobile
        border: 0
        font-size: 24px

  h1
    font-size: 40px
    margin-bottom: 16px
    line-height: 48px

    span
      background: -webkit-linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    @include mobile
      font-size: 24px
      line-height: 32px
      margin-bottom: 8px

  p.instruction
    font-size: 18px
    line-height: 28px
    letter-spacing: 0.01em

    @include mobile
      font-size: 14px
      line-height: 20px
      margin-bottom: 0px


  .container
    min-height: 101vh

  .column.body
    min-height: 100vh

  .image.hero-img
    top: 152px
    right: 0px
    position: absolute
    z-index: 99
    width: 50%

    @include mobile
      display: none
    
    img
      width: auto
      margin-left: 100px
      max-height: 530px

  .underlayer
    position: absolute
    background: #E4EFF6
    z-index: 1
    width: 30%
    height: 100%
    top: 0px
    right: 0px
    position: absolute

    @include mobile
      display: none

  .dynamic-form
    max-width: 700px
    padding: 1rem 0 3rem 0
    margin: 0 auto
    height: 100%
    display: flex
    flex-direction: column

  form
    flex: 1
    display: flex
    flex-direction: column
    justify-content: flex-start

    .question-container
      flex: 1

      @include mobile
        margin-top: 20px

  .progress-bar
    margin: 2rem 0 3rem 0
    width: 100%
    height: 1px
    background: #dcdfe6
    position: relative

    span.bar
      background: #F4B965
      height: 3px
      top: -1px
      left: 0px
      position: absolute
      display: block
      border-radius: 20px

    &.animated span.bar
      background: #F4B965
      @include transition(all)
      animation: quizbar 5s linear infinite

    @include mobile
      margin-bottom: 32px

  article.media
    border-bottom: 0
    align-items: center

    figure.media-left
      i
        font-size: 40px

    .media-content
      .content

        .name
          @include h5style
          font-size: 18px
          font-weight: 500
          line-height: 24px

          @include mobile
            font-size: 16px
            line-height: 24px

        .description
          font-size: 16px
          line-height: 24px
          color: #55778A

          @include mobile
            font-size: 12px
            line-height: 18px

  article.media + article.media
    border: 0

  .computation-state
    padding-top: 200px
    
    .icon
      width: 120px
      height: 120px
      margin: 0 auto

      i
        font-size: 80px
        animation: spinner 0.8s linear infinite
        color: $primary

    .description
      @include h5style

    .pros-found
      margin-bottom: 32px !important
      color: #0D9DA4 !important

      span
        font-weight: normal
        font-size: 14px
        line-height: 16px
        letter-spacing: -0.01em
      
      i
        color: #0D9DA4 !important
        position: relative
        top: 1px

        &:before
          color: #0D9DA4 !important

  form.simple_form
    .quiz-button-columns
      display: flex
      flex-wrap: wrap
      width: 100%
      justify-content: space-between

      a.quiz-button
        display: flex
        flex-basis: 48%
        margin-bottom: 1rem
        padding: 1.5rem 0 1.3rem 0
        outline: none !important
        box-shadow: none !important
        border-radius: 0
        padding: 0 10px
        justify-content: flex-start
        font-size: 14px
        line-height: 24px

        @include mobile
          flex-basis: 100%

        &:hover
          border-color: #0B3954
          background: white

        &:focus
          outline: none

        &.selected
          background: white
          border-color: #0D9DA4
          color: #0B3954

          &:after
            @include icon
            content: "\e980"
            position: absolute
            right: 10px
            background: -webkit-linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent  
            font-weight: bold


    .quiz-button-column
      display: flex
      flex-wrap: wrap
      width: 100%
      justify-content: space-between

      a.quiz-button
        display: flex
        flex-basis: 100%
        margin-bottom: 1rem
        padding: 1.5rem 0 1.3rem 0
        outline: none !important
        box-shadow: none !important
        border-radius: 0
        padding: 0 16px
        justify-content: flex-start
        font-size: 14px
        line-height: 24px

        @include mobile
          flex-basis: 100%

        &:hover
          border-color: #0B3954
          background: white

        &:focus
          outline: none

        &.selected
          background: white
          border-color: #0D9DA4
          color: #0B3954

          &:after
            @include icon
            content: "\e980"
            position: absolute
            right: 10px
            background: -webkit-linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent  
            font-weight: bold

    .button.is-back
      background: $grey-background
      border: $grey-background

      &:hover
        background: $primary
        color: white

    button[type=submit]
      font-size: 17px !important
      font-weight: bold !important
      line-height: 16px !important
      padding: 20px 30px !important
      
      &:before
        @include icon
        content: "\e9ad"
        position: absolute
        right: 20px
        font-size: 24px
        font-weight: bold

      @include mobile
        &:after
          display: none

    .action-buttons
      display: flex
      flex-wrap: wrap
      width: 100%
      justify-content: space-between

      .button
        display: flex
        flex-basis: 48%
        outline: none !important
        box-shadow: none !important
        padding: 14px 30px 10px 30px
        height: auto

        &.is-back
          background: $grey-background
          border: $grey-background

          &:hover
            background: $primary
            color: white
        @include mobile
          margin: 16px 0 !important
          height: 48px !important

      @include mobile
        display: block
        position: relative
        height: 112px

        .is-back
          bottom: 0
          position: absolute
          margin-bottom: 0 !important


body.quiz_results
  .underlayer
    position: absolute
    width: 100%
    background: #E4EFF6
    right: 0
    height: 300px

    @include mobile
      height: 48%
  section.hero
    margin-bottom: 3rem

    @include mobile
      margin-bottom: 1.5rem

    .inner
      padding: 4rem 0 4rem 0
      background: #F3F8FB
      background-position: center bottom
      background-size: cover
      text-align: center

      @include mobile
        padding: 2rem 0 3rem 0
        background-size: contain

    

    h1
      line-height: 40px
      font-weight: bold
      line-height: 48px
      margin-bottom: 24px
      text-align: left

      span
        background: -webkit-linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent

      @include mobile
        font-size: 24px
        line-height: 32px
        padding-top: 30px

    p.instruction
      @include h5style
      font-weight: 300
      margin-bottom: 1.5rem

      @include mobile
        font-size: 14px
        line-height: 20px
        text-align: left

    .retake-quiz
      position: relative
      top: 35%
      display: block

      a
        width: 40% !important

      @include mobile
        text-align: left
        padding-left: 24px
        padding-top: 20px

        a
          width: 60% !important
      
    
