table.standard
  width: 100%
  margin-bottom: 10px
  border-collapse: separate
  background: white

  thead
    tr
      text-transform: none

      th
        padding: 20px 20px 10px 20px
        text-align: left
        border-bottom: 1px solid #E4EFF6
        border-right: 0px
        color: #0B3954

        &:first-child
          padding-left: 40px

  tbody
    padding-left: 20px
    padding-right: 20px

    tr
      td
        padding: 20px
        text-align: left
        border-bottom: 1px solid #E4EFF6

        &:first-child
          padding-left: 40px

        &:last-child
          border-bottom: 0

        &.primary
          font-weight: 600

          span
            display: block
            margin-top: 8px
            font-size: 12px

        &.action
          text-align: right
          border-bottom: 1px solid #E4EFF6

        &.action.clients
          display: flex
          justify-content: space-evenly
          text-align: initial

        a.btn
          padding: 8px 20px
          border: 1px solid #E4EFF6
          border-radius: 40px

          &:hover
            background: $danger-color
            color: white
            z-index: 999

        &.with-icon
          vertical-align: middle
          font-size: 30px
          color: $primary-color

  &.clickable
    tbody tr
      cursor: pointer

      &:hover
        background: gray

    tbody tr.contracted:hover
      background: none
      cursor: initial

.table-tools
  margin-top: -10px
  margin-bottom: 20px

  a.tool
    position: relative
    margin-right: 10px
    @include transition(all)
    @include clearfix

    .title
      display: block
      float: left
      padding: 6px 15px 6px 20px
      border: 2px solid $light-neutral-color
      border-radius: 20px 0px 0px 20px
      color: #7d7d7d
      border-right: 0
      height: 37px

    .icon
      display: block
      float: left
      background: $light-neutral-color
      border-top: 2px solid $light-neutral-color
      border-bottom: 2px solid $light-neutral-color
      text-align: right
      font-size: 21px
      border-radius: 0px 20px 20px 0px
      padding: 0px 13px 0px 10px
      height: 37px
      line-height: 39px

    span.count
      position: absolute
      right: -6px
      top: -6px
      width: 20px
      height: 20px
      border-radius: 50%
      text-align: center
      background: black
      color: white
      font-weight: 500
      font-size: 14px
      line-height: 20px
      display: none

      &.active
        display: block
