@import 'application/fonts'
@import 'shared/icons'
@import 'application/bulma'
@import 'application/headings'
@import 'application/base'
@import 'application/blocks'
@import 'application/tabs'
@import 'application/footer'
@import 'application/pagination'
@import 'application/reviews'
@import 'application/forms'
@import 'application/practices'
@import 'application/articles'
@import 'application/localities'
@import 'application/questions'
@import 'application/modals'
@import 'application/autosuggest'
@import 'application/buttons'
@import 'application/badges'
@import 'application/navigation'
@import 'application/sessions'
@import 'application/flash'
@import 'application/quiz'
@import 'application/cards'
@import 'application/calendar'
@import 'application/messaging'
@import 'application/notices'
@import 'application/checkout'
@import 'application/transactions'
@import 'application/instant-actions'
@import 'application/new-design'
@import 'application/contact'
@import 'application/tables'
@import 'application/filestack-upload'
@import 'application/pro'
@import 'application/about'
@import 'application/testimonials'
@import 'application/pro-search'
@import 'application/practitioners'

@import "tailwindcss/base"
@import "tailwindcss/utilities"
@import "tailwindcss/components"

@layer base
  :root
    --color-black: 11 57 84 /* #0B3954 */
    --color-dark-gray: 85 119 138 /* #55778A */
    --color-gray: 200 214 223 /* #C8D6DF */
    --color-light-gray: 228 239 246 /* #E4EFF6 */
    --color-ultra-light-gray: 245 250 253 /* #F5FAFD */
    --color-teal: 13 157 164 /* #0D9DA4 */
    --color-dark-teal: 7 125 131 /* #077D83 */
    --color-orange: 244 185 101 /* #F4B965 */
    --color-light-orange: 254 248 240 /* #FEF8F0 */
    --color-red: 235 87 87 /* #EB5757 */
    --color-light-red: 255 233 250 /* #FFE9FA */
    --color-green: 38 196 168 /* #26C4A8 */
    --color-light-green: 231 255 227 /* #E7FFE3 */
    --color-blue: 143 213 252 /* #8FD5FC */
    --fc-now-indicator-color: #0D9DA4

  body
    @apply text-black font-normal text-base font-manrope leading-normal

  .portal-body main
    @apply p-8 md:p-6 md:pb-mobileNavBottom

  input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button, input[type='number']
    -webkit-appearance: none
    margin: 0
    -moz-appearance: textfield !important

  mark
    @apply bg-inherit text-inherit
