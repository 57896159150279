footer
  position: relative
  overflow: hidden
  background: #FFF
  margin-top: 0

  .container
    @include mobile
      text-align: center

  .list-practice-cta
    a
      padding: 14px 16px !important
      border-radius: 4px !important
      font-weight: 700 !important
      font-size: 16px !important
      line-height: 16px !important
      color: #FFF !important
      text-transform: none !important
      background: #0B3954
      &:hover
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0B3954

  .copyright-container
    @include mobile
      margin-top: 30px

  .copyright
    padding-top: 40px
    display: flex
    justify-content: center

  .copyright-text
    padding-top: 40px
    display: flex
    align-items: center
    text-align: center
    justify-content: center

  .logos
    display: flex
    flex-flow: column
    align-items: center
    justify-content: space-evenly
    .image img
      max-width: 103px
      margin-bottom: 15px

  .footer-links
    font-weight: 400
    line-height: 16px

  &.subfooter
    background: $grey-background
    padding: 6rem 1.5rem 7rem 1.5rem

    @include mobile
      padding: 4rem 1.5rem 5rem 1.5rem

    h2
      margin-bottom: 2rem

    .sub-footer-section:not(:last-child)
      padding-bottom: 3rem
      margin-bottom: 3rem
      border-bottom: 1px solid #dcdfe6

  &.action
    background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
    color: white
    text-align: left
    padding: 0rem 1.5rem 0rem 1.5rem

    p
      margin-bottom: 4rem

    a.btn
      padding: 20px 100px 18px 100px
      color: white
      border-radius: 100px
      font-weight: 500
      text-transform: uppercase

      background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      border: 0
      @include transition(all)
      @include mobile
        margin-bottom: 88px

      &:hover
        background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)

    &.localities
      top: 64px

    .background
      z-index: 1
      position: absolute

      &.a
        width: 70px
        bottom: 60px
        left: 190px

      &.b
        width: 40px
        top: 60px
        right: 200px

  &.primary
    color: #0B3954
    background: #FFF

    .background
      z-index: 1
      position: absolute

      &.a
        width: 500px
        bottom: 0px
        left: -300px

        @include mobile
          display: none

      &.b
        width: 700px
        bottom: -250px
        right: -300px

        @include mobile
          bottom: -150px
          right: -400px

    .column
      z-index: 10
      position: relative

    h3
      color: white
      margin-bottom: 1.25rem

    p
      font-size: 14px
      margin-bottom: 1.25rem
      font-weight: 400

    .heading
      font-family: 'Manrope'
      font-weight: 600 !important
      font-size: 18px
      line-height: 24px
      margin-bottom: 1.25rem

      @include mobile
        margin-bottom: 0.5rem

    nav.footer
      margin: 0
      padding: 0
      background: #FFF

      .heading
        font-size: 14px
        margin-bottom: 0
        margin-top: 1.25rem
        font-weight: 400

        &:first-child
          margin-top: 0

      a
        display: block
        padding: 5px 0
        font-size: 14px
        text-decoration: none
        @include transition(all)

        &:hover
          text-decoration: underline

    nav.footer-social
      margin: 0
      padding: 0

      a
        display: inline-block
        margin-right: 10px
        font-size: 30px
        @include transition(all)

        &:hover
          color: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0B3954

        &:last-child
          margin: 0
