.itembox
  border: solid 1px $grey-light
  font-size: 14px
  padding: 18px 15px 16px 15px
  position: relative

  &.inline
    display: inline-block
    padding: 15px 14px 13px 14px
    margin-right: 8px
    margin-bottom: 10px

  &.mini
    font-size: 12px
    display: inline-block
    padding: 8px 14px 7px 14px
    margin-right: 6px
    margin-bottom: 6px
    border-radius: 10px

  &[data-kind='expander']
    box-shadow: 0 4px 6px -5px rgba(107, 121, 153, 0.35)
    cursor: pointer
    min-width: 130px
    @include transition(all)

    &:hover
      background: $primary
      border-color: $primary
      color: white

  &.with-icon span.name
    padding-right: 30px

  span.name
    display: inline-block

  span.metadata
    position: absolute
    right: 10px
    top: 18px
    display: inline-block

  span.action
    position: absolute
    right: 10px
    top: 11px
    display: inline-block
    font-size: 20px
    line-height: 38px
    width: 32px
    height: 32px
    color: $grey
    border-radius: 50%
    text-align: center
    background: white
    @include shadow
    @include transition(all)

  &:hover
    span.action
      color: $success
      border-color: white

.masonry
  display: flex
  flex-wrap: wrap
  margin: 0 -5px

  .itembox
    flex: 1 0 auto
    margin: 5px

  &[data-count='1'], &[data-count='2'], &[data-count='3'], &[data-count='4']
    .itembox
      flex: none
 
.well
  background: $grey-background
  padding: 5px
  border-radius: 16px

  .inner
    border-radius: 16px
    border: 1px solid $grey-light
    padding: 15px
    margin: 1px
    position: relative

    a.close
      position: absolute
      top: 15px
      right: 15px
      color: $grey

      &:hover
        color: $primary

    .logo
      max-width: 100px
      max-height: 100px

      img, svg
        max-width: 100px
        max-height: 100px

    .name
      font-size: 16px
      font-weight: 500

    .description
      font-size: 14px
      font-weight: 400
      color: $black-light
      margin-top: 10px

  &.vertical
    text-align: center
    height: 100%

    .inner
      height: 100%

    .badge
      margin-top: 1rem

    .logo
      max-height: initial
      max-width: initial
      text-align: center
      margin: 10px 0 30px 0

      img
        max-width: initial
        max-height: 130px
        height: 130px

.related-items
  border: 0
  margin-bottom: 2rem

  .heading
    padding-bottom: 12px
    text-transform: uppercase
    font-size: 14px
    border-bottom: 1px solid $grey-light
    font-weight: 500

  .listing
    border-top: 0

    article.media
      border-top: 0
      padding: 10px 0px
      padding-bottom: 0px
      margin: 0

      figure
        padding: 0

      .content
        margin-bottom: 8px

        ul
          margin-top: 0
          margin-bottom: 0
          margin-left: 1.4em
        a
          font-weight: 500

      .item-title a
        font-weight: 500
        line-height: 12px

        &:hover
          color: $contrast

      .description
        color: $black-light
        margin-top: 8px
        font-size: 12px

      .image img
        width: 48px
        height: 48px
        border-radius: 8px
        object-fit: cover

      &:last-child .content
        border-bottom: 0

  &.articles a
    color: $black

    &:hover
      color: $primary

.call-to-action
  background: $primary
  @include shadow
  border-color: $primary
  border-radius: 16px
  padding: 25px 25px 45px 25px
  color: white
  text-align: center
  margin-bottom: 2rem

  &:last-child
    margin-bottom: 0

  h4
    color: white
    @include headingfont
    font-size: 28px
    font-weight: 400

  p
    margin-bottom: 2rem
    font-size: 14px

  a.btn
    padding: 13px 30px 10px 30px
    color: white
    border: 1px solid white
    border-radius: 10px
    font-weight: 500

    &:hover
      background: white
      color: $primary

.bank-account

  .name
    font-weight: 500

  .account-number
    span
      display: inline-block
      font-family: Menlo, Consolas, monospace
      font-size: 14px
      color: $black-light

      &:first-child
        margin-right: 10px
