body.questions.index
  background: $grey-background
 
  .heading
    text-align: center
    margin-bottom: 2rem

    h1
      margin-bottom: 0.75rem

    .description
      @include h5style
      font-weight: 300
      margin-bottom: 1rem

  .btn-primary
    i
      top: 16px

  .load-more
    text-align: center

    a
      font-size: 14px
      @include shadow
      font-weight: 400
      padding: 24px 30px 22px 30px

      &:hover
        background: $primary
        color: white
        transform: scale(1.1)

  .question-with-answer
    border: 1px solid $grey-light
    background: white
    border-radius: 16px
    margin-bottom: 2rem

    .question
      padding: 20px
      position: relative

      .title
        @include h2style
        font-size: 26px
        line-height: 34px
        margin-bottom: 8px
        padding-right: 170px

        @include mobile
          font-size: 20px
          line-height: 30px

        a
          color: $black

          &:hover
            color: $primary

      .answer-count
        font-size: 14px
        color: $black-light
        margin-bottom: 8px

      .tags
        margin-bottom: 8px

      .helpful
        position: absolute
        top: 15px
        right: 15px
        z-index: 20

    .answer
      padding: 20px
      background: $grey-background
      border-radius: 16px
      margin: 0 10px 10px 10px

      > .content
        @include mobile
          font-size: 15px

    article.media
      figure.media-left

        .image
          padding: 4px
          border: 1px solid $primary
          border-radius: 50%
          display: inline-block

          img
            width: 48px
            height: 48px
            border-radius: 50%

      .media-content
        @include mobile
          margin-bottom: 10px

        .content
          position: relative

          .date
            position: absolute
            top: 0px
            right: 0px
            color: $black-light
            font-size: 14px

            @include mobile
              display: none

          .name a
            font-size: 18px
            color: $black
            font-weight: 500

            &:hover
              color: $primary

            @include mobile
              font-size: 16px

          .title
            margin-top: 3px
            @include subheading
            font-size: 12px
            line-height: 18px

  footer
    margin-top: 0
    
.answers-count
  font-weight: bold
  font-size: 14px
  letter-spacing: 0.03em
  text-transform: uppercase
  color: #55778A


.answer-for-question
  padding: 0 25px
  background: $grey-background

  @include mobile
    padding: 15px

  figure.media-left

    .image
      padding: 4px
      display: inline-block

      img
        width: 56px
        height: 56px

        @include mobile
          width: 40px
          height: 40px

  .media-content
    .content
      position: relative

      .helpful
        position: absolute
        top: 0px
        right: 0px
        z-index: 20

      .date
        color: $black-light
        font-size: 14px
        margin-bottom: 1rem

        @include mobile
          position: relative
          top: initial
          right: initial
          margin: 0 0 0.5rem 0

      .name
        padding-right: 110px
        font-size: 18px
        color: $black
        font-weight: 500

        @include mobile
          padding-right: 0

        a
          font-size: 18px
          color: $black
          font-weight: 500

          &:hover
            color: $primary

      .title
        @include subheading
        font-size: 12px
        line-height: 18px
        padding-right: 110px
        margin-bottom: 0.5rem
        margin-top: 3px

        @include mobile
          margin-bottom: 0.4rem

  .body
    margin-top: 0.5rem

  .actions
    margin-top: 14px

    a
      font-size: 14px
      font-weight: 500
      display: inline-block
      margin-right: 10px
      padding: 8px 10px 12px 10px

  form.simple_form
    textarea
      font-size: 16px
      height: 150px
      border-radius: 0
      padding: 20px

    .input-wrapper.text
      margin-bottom: 0



form.simple_form.new_question
  .autocomplete-selected-items
    margin-top: -1.5rem
