$homepage-primary-color: #0B3954
$homepage-hover-color: #0D9DA4

body.public.index, body.applications, body.contacts, body.pro
  @include homepage-standard
  background: #F3F8FB

  nav.navbar
    .navbar-brand a.navbar-burger
      line-height: 71px

      .navbar-dropdown
        border-radius: 0px

        .navbar-item
          padding: 10px 25px

      a.button.is-transparent
        font-size: 16px
        font-weight: 500
        text-transform: uppercase
        padding: 25px 35px
        margin-top: 4px

  h3
    @include homepage-heading
    font-size: 40px
    line-height: 50px
    font-weight: bold
    text-align: left
    font-size: 40px

    span
      background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    @include mobile
      font-size: 21px
      line-height: 32px

  a.button
    font-weight: 500
    border-radius: 100px
    font-size: 16px
    text-transform: uppercase
    padding: 32px 36px 30px 36px
    text-align: center
    box-shadow: none

    @include mobile
      padding: 28px 36px 26px 36px
      width: 100%

    &.is-default
      background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
      color: white
      border: 0
      @include transition(all)

      &:hover
        background: linear-gradient(225.47deg, $homepage-hover-color 1%, $homepage-primary-color 113.27%)

    &.is-transparent
      border: 1px solid #C8D6DF
      background: transparent
      color: $homepage-primary-color

      &:hover
        background: linear-gradient(215.91deg, #EDB364 12.73%, #E9856D 85.52%)
        color: white

  .action-call
    text-align: center
    display: inline-block

    @include mobile
      width: 100%

    &.block
      display: block

      @include mobile
        padding: 0 41px
    .schedule-btn
      font-style: normal
      font-weight: bold
      font-size: 17px
      line-height: 16px
      text-align: center
      text-transform: uppercase
      color: #55778A
      position: relative
      top: 20px
      padding: 30px 13px 30px 13px
      font-size: 17px
      line-height: 16px

      @include mobile
        top: 20px
        margin-left: 0
        margin: auto
        padding: 30px 18px 30px 18px

      &:hover
        color: #0B3954

  .action-call-secondary
    text-align: left
    margin-top: 28px
    display: inline-block
    margin-top: 3rem

    @include mobile
      margin-top: 8px
      width: 100%
    a.button
      background: transparent
      border: 0
      color: #55778A
      font-weight: bold

      @include mobile
        padding: 30px 18px 30px 18px
      &:hover
        color: #0B3954
        background: transparent
        border: 0

  ul.bullet-points
    list-style-type: none

    li
      margin-bottom: 1rem
      padding-left: 35px
      position: relative

      &.orange
        color: #E9856D

      i
        position: absolute
        top: 0px
        left: 0px
        font-size: 21px

        &.white
          color: white

        &.accent
          color: #E9856D

  footer
    background: #FFF
    color: $homepage-primary-color

    @include mobile
      padding: 50px 15px

    .logo
      margin-left: -10px
      margin-bottom: 15px

    .heading
      font-weight: 500
      margin-bottom: 15px

    nav.footer
      background: transparent
      color: $homepage-primary-color

      a
        color: $homepage-primary-color

body
  .navbar.is-fixed-top
    top: 0px

  .status-banner
    z-index: 999
    display: block
    position: fixed
    top: 0
    left: 0
    right: 0
    background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
    color: white
    text-align: center
    font-weight: 700
    padding: 7px

    &::before
      content: 'Your profile is not yet live.'

      @include mobile
        display: none

    &::after
      content: 'Finish setting up your account.'
      text-decoration: underline
      margin-left: 2px

      @include mobile
        content: 'Finish setting up your account.'
        text-decoration: underline
        margin-left: 0

body.practitioner_searches
  .navbar.is-fixed-top
    top: 0

  .status-banner
    z-index: 999
    display: block
    position: fixed
    top: 0
    left: 0
    right: 0
    background: linear-gradient(225.47deg, #0D9DA4 1%, #0B3954 113.27%)
    color: white
    text-align: center
    font-weight: 700
    padding: 7px

    &::before
      content: 'Your profile is not yet live.'

      @include mobile
        display: none

    &::after
      content: 'Finish setting up your account.'
      text-decoration: underline
      margin-left: 2px

      @include mobile
        content: 'Finish setting up your account.'
        text-decoration: underline
        margin-left: 0

body.practitioners
  .navbar.is-fixed-top
    top: 0 !important

  .status-banner
    display: none

.not_active
  top: 38px !important
body.public.index
  background: #FFF
