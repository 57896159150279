nav.pagination
  @include subheading
  padding-top: 10px
  display: block
  text-align: center
  margin-top: 1.5rem
  margin-bottom: 1.5rem

  a, span.current
    padding: 0
    font-size: 12px
    display: inline-block
    color: #0B3954
    text-align: center
    @include transition(color)
    background: white
    border-radius: 0
    width: 30px
    height: 30px
    line-height: 30px
    border: 1px solid white
    margin: 0px
    font-weight: 500

    &:hover
      background: linear-gradient(227.66deg, #3EB1B6 0%, #3D6B7E 100%)
      color: white !important

  span.next, span.last, span.prev, span.first
    a
      line-height: 30px
      @include shadow

  a:hover
    color: $contrast

  span.current
    background: linear-gradient(227.66deg, #3EB1B6 0%, #3D6B7E 100%)
    color: white

    &:hover
      background: linear-gradient(227.66deg, #3EB1B6 0%, #3D6B7E 100%)
      color: white

nav.breadcrumb
  li + li::before
    @include icon
    color: #b5b5b5
    content: "\e944"

  li
    color: $black-light
    font-weight: 300

    a
      color: $black
      font-weight: 400
      padding: 0 0.45em

      &:hover
        color: $contrast

    &.no-chevron::before
      content: ''


