.modal
  .modal-background
    background-color: rgba(0, 0, 0, 0.55)
    opacity: 0.8
  .modal-content
    .box
      position: relative

      button.close, button.no-close
        position: absolute
        right: 10px
        top: 20px
        font-size: 25px
        background: none
        border: 0
        cursor: pointer
        @include transition(all)

        &:hover
          color: $contrast
        
      button.close-fullwidth
        position: relative 
        left: 10px 
        bottom: 10px 
        width: 80% 
        height: 48px
        margin: 20px 40px


      .scroll-area
        max-height: calc(72vh)
        overflow-y: scroll
        overflow-x: hidden
        margin-bottom: 1rem
        padding-right: 32px

        .inner
          margin-right: 10px

      p.instruction
        color: $black-light
        font-size: 14px